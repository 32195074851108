import React, { createContext } from "react";
import "./App.scss";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ScrollToTop from "../helpers/ScrollToTop";
import Api from "../utils/Api";
import { useNavigate } from "react-router-dom";
import {ErrorPopup} from "../components/ErrorPopup";
import {Loader} from "../ui/Loader";
import { changePageWidth } from "../store/redusers/pageWidthReducer";
import { AppRouter } from "../components/AppRouter/AppRouter";
export const Context = createContext(null)

function App() {
	const [isAuthorized, setIsAuthorized] = React.useState(false);
	const [currentLocation, setCurrentLocation] = React.useState("");
	const [error, setError] = React.useState(""); //тут храним ошибку сервера при регистрации или логине.
	const [isErrorPopupOpen, setIsErrorPopupOpen] = React.useState(false); // стейт состояния окошка с ошибкой
	const [isLoading, setIsLoading] = React.useState(false); // индикатор загрузки
	const [currentUser, setCurrentUser] = React.useState({}); // Данные текущего юзера
	const [accountPopupOpened, setAccountPopupOpened] = React.useState(false); // Попап с настройками личного кабинета

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	window.onresize = newPageSize;

	function newPageSize() {
		dispatch(changePageWidth(document.documentElement.scrollWidth));
	}

	React.useEffect(() => {
		const tokenCheck = () => {
			const path = location.pathname;
			const jwt =
				localStorage.getItem("token") ||
				sessionStorage.getItem("token");

			if (jwt !== null && !["/sign-in", "/sign-up"].includes(path)) {
				setIsLoading(true);
				Api.checkToken(jwt)
					.then(() => {
						Api.getUser().then((data) =>
							setCurrentUser({
								username: data.username.split("+")[0],
								id: data.id,
								email: data.email,
								phone: data.phone_number,
								firstName: data.first_name,
								lastName: data.last_name,
							})
						);
						setIsAuthorized(true);
						navigate(path);
					})
					.catch((err) => {
						setIsErrorPopupOpen(true);
						setError(err.message);
					})
					.finally(() => {
						setIsLoading(false);
					});
			} else setIsLoading(false);
		};

		tokenCheck();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthorized]);

	const handleLogin = (
		{ email, password, needRememberUser = false },
		isReg
	) => {
		Api.loginUser(email, password)
			.then((data) => {
				if (data.access) {
					needRememberUser
						? localStorage.setItem("token", data.access)
						: sessionStorage.setItem("token", data.access);
					Api.getUser().then((data) => setCurrentUser(data));
					setIsAuthorized(true);
					setError("");
					isReg ? navigate("/welcome-page") : navigate("/profile");
				}
			})
			.catch((err) => {
				setError(
					Object.values(err).length !== 0
						? Object.values(err)
						: Array(err.message)
				);
				setIsErrorPopupOpen(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleRegistration = ({
		firstName,
		lastName,
		phone,
		email,
		password,
	}) => {
		setIsLoading(true);
		Api.registerUser({ firstName, lastName, phone, email, password })
			.then((data) => {
				handleLogin({ email: data.email, password }, true);
			})
			.catch((err) => {
				setError(
					Object.values(err).length !== 0
						? Object.values(err)
						: Array(err.message)
				);
				setIsErrorPopupOpen(true);
				setIsLoading(false);
			});
	};
	const handlePopupClose = () => {
		setIsErrorPopupOpen(false);
		setError("");
	};

	const handleAccountPopupOpen = () => {
		setAccountPopupOpened(true);
	};

	const handleAccountPopupClose = () => {
		setAccountPopupOpened(false);
	};

	const onSignOut = () => {
		setIsAuthorized(false);
		localStorage.removeItem("token");
		sessionStorage.removeItem("token");
		setAccountPopupOpened(false);
		navigate("/");
	};

	const onUpdateUser = (data) => {
		Api.sendUserInfo(data)
			.then((user) => {
				setCurrentUser(user.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="app__wrapper">
			<div className="app__content">
        <Context.Provider value={{
          isAuthorized,
          handleAccountPopupOpen,
          currentUser,
          accountPopupOpened,
          handleAccountPopupClose,
          onSignOut,
          onUpdateUser,
          currentLocation,
          isLoading,
          setIsLoading,
          handleLogin,
          handleRegistration, 
          setCurrentLocation
        }}>
        <ScrollToTop>
          <AppRouter />
				</ScrollToTop>
				<ErrorPopup
					isOpen={isErrorPopupOpen}
					error={error}
					onClose={handlePopupClose}
				/>
        </Context.Provider>
			</div>
		</div>
	);
}

export default App;
