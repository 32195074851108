import React from 'react';
import './CorrectAnswersCount.scss';

export const CorrectAnswersCount = ({wrongAnswers, game, steps}) => {
    const correctAnswers = steps - wrongAnswers;

    let classesInner = "correct-answers-inner"

    switch (game) {
        case "one":
            classesInner += ' correct-answers-inner-one';
            break;
        case "two":
            classesInner += ' correct-answers-inner-two';
            break;
        default:
            classesInner = 'correct-answers-inner';
    }


    return (
        <div className="correct-answers">
            <p className={classesInner}>
                {`Правильных ответов: ${correctAnswers} из ${steps}`}
            </p>
        </div>
    );
};