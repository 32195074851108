import './GameTask.scss';
import { ReactComponent as CorrectIcon } from '../../images/icons/correct-icon.svg';
import { ReactComponent as IncorrectIcon } from '../../images/icons/incorrect-icon.svg';


export function GameTask({ task, onAnswerClick }) {
  const { answers, question, selectedId, checked } = task;
 
  return (
    <div className='fourth-game__task task'>
      <div className='task__question'>
        <span>{question}</span>
      </div>
      <ul className='task__answer-list'>
      {answers.map(({ id, answer, correct }) => (
        <li
          key={id}
          className='task__answer-item answer-item'
          onClick={() => onAnswerClick(id)}
          data-selected={selectedId === id}
          data-task-checked={checked}
          data-correct={correct}
        >
          <span className='answer-item__text'>{answer}</span>
          {checked && selectedId === id && (
            <div className='answer-item__icon-wrapper'>
              {correct ?
                <CorrectIcon className='answer-item__icon' /> :
                <IncorrectIcon className='answer-item__icon' />}
            </div>)}
        </li>
      ))}
    </ul>
    </div>
  );
}