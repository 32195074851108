import React from 'react';
import {Link, useParams} from 'react-router-dom';
import './TestButton.scss';

function TestButton( {step, quizQuestions, buttonClass, handleButtonClick, input, handleFinalButtonClick} ) {

    const {numberTest} = useParams();

    return (
        <>
            { step < quizQuestions.questions.length - 1
                ?
                <button disabled={!input} className={input ? buttonClass.join(' ') :  buttonClass.join(' ') + ' disabled'} onClick={handleButtonClick}>Дальше</button>
                :
                <Link className="test-button__link" to={`/test-results/${numberTest}`}>
                    <button onClick={handleFinalButtonClick} disabled={!input} className={input ? 'test-button test-button_final' : 'test-button test-button_final disabled'}>
                        Узнать результат
                    </button>
                </Link>
            }
        </>
    );
}

export default TestButton;
