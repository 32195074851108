import {classesNames} from "../../constants/classesArray";
import './ClassesList.scss';
import Tilt from "react-parallax-tilt";
import {Link} from "react-router-dom";
import React from "react";


export const ClassesList = () => {

    return (
        <div className="main__courses">
            {classesNames.map((i, key) =>
                i.id === 'first' ? (
                    <div className="main__courses-container" key={key}>
                        <Tilt>
                            <Link className="main__courses-link" to={i.path} id={i.id}>
                                <p className="main__courses-text">{i.name}</p>
                            </Link>
                        </Tilt>
                        <div className="main__courses-books"></div>
                    </div>
                ) : (
                    <Tilt key={key}>
                        <Link className="main__courses-link" to={i.path} id={i.id}>
                            <p className="main__courses-text">{i.name}</p>
                        </Link>
                    </Tilt>
                ),
            )}
        </div>
    )
}