import React from 'react';
import { Link } from 'react-router-dom';
import './StartButton.scss';

function StartButton( {link} ) {
    return (
        <div className="start-test">
            <Link className="start-test__link" to={link}>
                <button className="start-test__button">
                    Приступить
                </button>
            </Link>
        </div>
    );
}

export default StartButton;
