import { shuffle } from './shuffle';

const transformGameData = (tasks) => {
  return [...tasks].map((task) => {
    const { 
            right_answer,
            not_right_answer_1,
            not_right_answer_2,
            not_right_answer_3,
            exercise_text,
            id,
          } = task;

    const answers = [
      { id: 1, answer: right_answer, correct: true },
      { id: 2, answer: not_right_answer_1, correct: false },
      { id: 3, answer: not_right_answer_2, correct: false },
      { id: 4, answer: not_right_answer_3, correct: false },
    ];

    return {
      id,
      question: exercise_text,
      answers: shuffle(answers),
      checked: false,
      selectedId: null,
      correct: null,
    };
  });
};

export { transformGameData };