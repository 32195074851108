import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
    pageWidth: document.documentElement.scrollWidth,
}

export const changePageWidth = createAction('CHANGEPAGEWIDTH');

export default createReducer(initialState,{
    [changePageWidth]: function(state, action) {
        state.pageWidth = action.payload
    },
})