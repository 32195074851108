import React from 'react';
import './ResultTaskButton.scss';

function ResultTaskButton({ questionNumber, question, setStep, checkedAnswers }) {
    let classes = 'result-task__button';

    if(checkedAnswers.right_question) {
        if(checkedAnswers.right_question[question.id] === 1 && questionNumber % 2 === 0) {
            classes += ' result-task__button_even result-task__button_even-correct';
        } else if (checkedAnswers.right_question[question.id] === 1 && questionNumber % 2 !== 0) {
            classes += ' result-task__button_odd result-task__button_odd-correct';
        } else if (checkedAnswers.right_question[question.id] === 2 && questionNumber % 2 === 0) {
            classes += ' result-task__button_even result-task__button_even-almost';
        } else if (checkedAnswers.right_question[question.id] === 2 && questionNumber % 2 !== 0) {
            classes += ' result-task__button_odd result-task__button_odd-almost';
        } else if (checkedAnswers.right_question[question.id] === 3 && questionNumber % 2 === 0) {
            classes += ' result-task__button_even result-task__button_even-wrong';
        } else if (checkedAnswers.right_question[question.id] === 3 && questionNumber % 2 !== 0) {
            classes += ' result-task__button_even result-task__button_odd-wrong';
        }
    }

    const checkAnswer = () => {
        setStep(questionNumber - 1);
    }

    return <button onClick={() => checkAnswer()} className={classes}>{questionNumber}</button>;
}

export default ResultTaskButton;