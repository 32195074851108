import React from 'react';
import ResultsButton from "../ResultsButton/ResultsButton";
import BuyPracticumButton from "../../../Practicum/components/BuyPracticumButton/BuyPracticumButton";
import ResultsTitle from "../ResultsTitle/ResultsTitle";
import './ResultsCover.scss';

const ResultsCover = ({checkedAnswers, taskNumber, currentLocation, authorized}) => {

    let classes = "results-cover";

    if (checkedAnswers.victory_text) {
        if (checkedAnswers.victory_text.split(' ')[2] === 'прекрасно') {
            classes += " results-cover_excellent";
        } else if (checkedAnswers.victory_text.split(' ')[2] === 'неплохо') {
            classes += " results-cover results-cover_good";
        } else if (checkedAnswers.victory_text.split(' ')[2] === 'пока') {
            classes += " results-cover_bad";
        }
    }

    return (
        <div className={classes}>
            <ResultsTitle checkedAnswers={checkedAnswers} taskNumber={taskNumber}/>
            {classes !== "results-cover results-cover_bad"
                ?
                <ResultsButton text="Поделиться в ВК" className="test-results__button test-results__button_share-vk" />
                :
                <BuyPracticumButton taskNumber={taskNumber} />
            }
        </div>
    )
};

export default ResultsCover;
