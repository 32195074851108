import React from 'react';
import './Counter.scss';
import { useProgressBarContext } from '../../context';
import { getClassNames } from '../../../../../../../helpers/getClassNames';

export function Counter({ className }) {
    const classNames = getClassNames(['game-progress-bar__count', className]);
    const { countSteps, steps } = useProgressBarContext();

    return (
        <p className={classNames}>{countSteps} из {steps}</p>
    );
};