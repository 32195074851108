import React from 'react';
import LoadingImage from '../images/loader.gif';
import './Loader.scss';

export const Loader = () => {
  return (
      <div className="loader-container">
          <img
            className="loader"
            src={LoadingImage}
            alt="Изображение лоадера"
          />
      </div>
  );
}
