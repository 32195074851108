import React from 'react';
import Tilt from 'react-parallax-tilt';
import {Header} from '../../../../modules/Header';
import './StartTest.scss';
import {PageTitle} from '../../../../ui/PageTitle';
import {PageSubtitle} from '../../../../ui/PageSubtitle';
import StartButton from '../StartButton/StartButton';
import {Footer} from '../../../../modules/Footer';
import {BackButton} from "../../../../ui/BackButton";
import {useParams} from "react-router-dom";

export const StartTest = ({ authorized, name }) => {
    const {numberTest} = useParams();

      return (
        <div className="welcome-tests-page">
        <Header authorized={authorized} name={name} />
          <div className="welcome-tests-page__content">
            <BackButton path="/ege"/>
            <PageTitle text="Русский язык" className="page-title welcome-tests-page__content-title" />
            <PageSubtitle testNumber={numberTest} text=" задание"/>
            <PageSubtitle text="Тест"/>
            <Tilt>
              <StartButton link={`/tests/${numberTest}`} />
            </Tilt>
          </div>
          <Footer />
        </div>
      );
}
