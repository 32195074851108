import React from "react";
import leaves from "./images/leaves.svg";
import seashell from "./images/seashell.svg";
import stars from "./images/stars.svg";
import flowers from "./images/flowers.svg";
import { useSelector } from "react-redux";
import "./resultBtn.scss";

const ResultBtn = ({ handleOpen, game, newGame, disabled, text }) => {
	const checkAnswers = useSelector((state) => state.game.checkAnswers);

	let decorativeImg;
	let decorativeImgClasses = 'decorative-img';
	let classes = 'result-btn';

	switch (game) {
		case 'one':
			decorativeImg = leaves;
			classes += ' result-btn-one';
			break;
		case 'two':
			decorativeImg = seashell;
			decorativeImgClasses += ' decorative-img-two'
			classes += ' result-btn-two';
			break;
		case 'third':
			decorativeImg = stars;
			decorativeImgClasses += ' decorative-img-three'
			classes += ' result-btn-three';
			break;
		case 'four':
			decorativeImg = flowers;
			decorativeImgClasses += ' decorative-img-four'
			classes += ' result-btn-four';
			break;
		default:
			decorativeImg = leaves;
	}

	return (
		<button disabled={disabled} className={classes} onClick={checkAnswers ? newGame : handleOpen} type="button">
			<img src={decorativeImg} alt="декоративная картинка" className={decorativeImgClasses} />
			{checkAnswers ? 'Начать заново' : text}
		</button>
	);
};

export default ResultBtn;
