import {ProgressBar} from "../../../components/progress-bar";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./SecondGame.scss";
import {getGameDetails} from "../../api/Api";
import {DragDropContext} from "react-beautiful-dnd";
import {DragCard} from "../../../components/DragCard/DragCard";
import {nextStep, prevStep, setCheckAnswers} from "../../../store/gameSlice";
import {checkResults} from "../../helpers/checkResult";
import {initialContainers} from "../../constants/initialContainers";
import {renderInitialData} from "../../helpers/renderInitialData";
import ResultBtn from "../../../components/result-btn/resultBtn";
import {CorrectAnswersCount} from "../../../components/CorrectAnswersCount/CorrectAnswersCount";
import {useParams} from "react-router-dom";
import {CorrectAnswers} from "../CorrectAnswers/CorrectAnswers";

export function SecondGame() {
  const checkAnswers = useSelector((state) => state.game.checkAnswers);
  const count = useSelector((state) => state.game.countSteps);
  const [title, setTitle] = useState('');
  const [words, setWords] = useState([]);
  const [containers, setContainers] = useState(initialContainers);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
  const [showedCorrectAnswers, setShowCorrectAnswers] = useState(false);
  const dispatch = useDispatch();
  const [draggedItemId, setDraggedItemId] = useState(null);
  const steps = words.length;
  let { gameNumber } = useParams();

  useEffect(() => {
    if(gameNumber) {
      getGameData(gameNumber);
    }
  }, [gameNumber])

  useEffect(() => {
    renderInitialData(words, containers, setContainers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [words]);

  async function getGameData(number) {
    try {
      const response = await getGameDetails(number);
      if(response.status === 200) {
        setTitle(response.data['exercise_text']);
        setWords(response.data['tasks']);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const onDragStart = (start) => {
    setDraggedItemId(start.draggableId);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceContainer = containers.find((container) => container.id === result.source.droppableId);
    const destinationContainer = containers.find((container) => container.id === result.destination.droppableId);

    const sourceItems = Array.from(sourceContainer.tasks);
    const destinationItems = Array.from(destinationContainer.tasks);

    const [reorderedItem] = sourceItems.splice(result.source.index, 1);
    destinationItems.splice(result.destination.index, 0, reorderedItem);

    const updatedContainers = containers.map((container) => {
      if (container.id === result.source.droppableId) {
        container.tasks = sourceItems;
      } else if (container.id === result.destination.droppableId) {
        container.tasks = destinationItems;
      }
      return container;
    });

    if(Number(destinationContainer.id) > 12 && Number(sourceContainer.id) < 13 && destinationContainer.id !== sourceContainer.id) {
      dispatch(nextStep());
    } else if (Number(destinationContainer.id) < 13 && Number(sourceContainer.id) > 12 && destinationContainer.id !== sourceContainer.id) {
      dispatch(prevStep());
    }

    setContainers(updatedContainers);
    setDraggedItemId(null);
  };

  const finishGame = () => {
    dispatch(setCheckAnswers(true));
  }

  const resetGame = () => {
    window.location.reload();
  }

  if(words.length === 0) {
    return <div></div>
  } else {
    return (
        <div className="second-game">
          <ProgressBar game="two" steps={steps} wrongAnswers={wrongAnswersCount}/>
          {showedCorrectAnswers
              ?
              <CorrectAnswers words={words} />
              :
              <>
                <div className="correct-answers-container">
                  {checkAnswers ? (
                      <CorrectAnswersCount wrongAnswers={wrongAnswersCount} game="two" steps={steps} />
                  ) : (
                      <h2 className="second-game__title">{title}</h2>
                  )}
                </div>
                <div className="second-game__content">
                  <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <div className="words-container">
                      {containers.map((container) =>
                              Number(container.id) < 13 && (
                                  <DragCard game="two"  container={container} isHovered={draggedItemId} />
                              )
                      )}
                    </div>
                    <div className="answers-container">
                      {containers.map((container) =>
                              Number(container.id) >= 13 && (
                                  <DragCard game="two" container={container} isHovered={draggedItemId} />
                              )
                      )}
                    </div>
                  </DragDropContext>
                </div>
              </>
          }
          <div className="buttons-area">
            <ResultBtn game="two" text="Узнать результат" disabled={count !== steps} handleOpen={() => checkResults(containers, setContainers, setWrongAnswersCount, finishGame)} newGame={resetGame}/>
            {wrongAnswersCount !== 0 &&
                <button onClick={() => setShowCorrectAnswers(!showedCorrectAnswers)} className="answers-button">{showedCorrectAnswers ? "Вернуться" : "Ответы"}</button>
            }
          </div>
        </div>
    );
  }
}


