import React from 'react';
import './StepsList.scss';
import { useProgressBarContext } from '../../context';
import { getClassNames } from '../../../../../../../helpers/getClassNames';

export function StepsList({ initialIcon, finalIcon, renderSteps, className }) {
  const classNames = getClassNames(['game-progress-bar__steps', className]);
  const { countSteps, steps, pageWidth } = useProgressBarContext();
  let width;

  if (pageWidth > 1200) {
    width = 1200 * 0.8 * 0.8;
  } else if (pageWidth > 450) {
    width = pageWidth * 0.8 * 0.8;
  } else {
    width = pageWidth * 0.95 * 0.8;
  }

return (
  <div className='game-progress-bar__steps-wrapper'>
  <ul className={classNames}>
    <img
      alt=""
      src={countSteps === steps ? finalIcon : initialIcon}
      className="game-progress-bar__progress"
      style={
        countSteps === steps
            ? { left: `${countSteps * (width / steps) - (width / steps * 0.8)}px`, bottom: "-15px" }
            : { left: `${countSteps * (width / steps) - (width / steps * 0.1)}px` }
    }
    />
    {Array(steps).fill().map((_, idx) => renderSteps(idx))}
  </ul>
  </div>
);
};