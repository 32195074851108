import { useState, useEffect, useCallback, useRef } from 'react';
import { getGameData } from '../api/Api';
import { transformGameData } from '../helpers/transformGameData';

const useTasks = ({ gameID }) => {
  const initialGameData = useRef(null);
  const [tasks, setTasks] = useState();
  const [stepsState, setStepsState] = useState();
  const [currentTask, setCurrentTask] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getTasks = useCallback(() => {
    setLoading(true);
    setError('');

    getGameData(gameID)
      .then((data) => {
        const { tasks } = data;
        const transformed = transformGameData(tasks);
        setTasks([...transformed]);
        setStepsState([...transformed]);
        initialGameData.current = [...tasks];
      })
      .catch((error) => {
        const { message } = error;
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [gameID]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return {
    tasks,
    isLoading,
    error,
    currentTask,
    setCurrentTask,
    setTasks,
    setStepsState,
    stepsState,
    initialGameData: initialGameData.current,
  }
}

export { useTasks };