import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import './MultipleAnswer.scss';
import {FormattedParagraph} from "../../../ui/FormattedParagraph";
import ResultMultipleCheckbox from "../../../pages/TestResults/components/ResultMultipleCheckbox/ResultMultipleCheckbox";

export const MultipleAnswer = ({handleMultipleAnswerClick, isChecked, question, answer}) => {
    const {pathname} = useLocation();
    const answerCopy = [];
    let questionCopy = {};
    const userAnswers = [];
    const {numberTest} = useParams();

    if(pathname === `/test-results/${numberTest}`) {
        if(question.answers && answer) {
            Object.assign(questionCopy, question);
            answer[0].forEach((i) => {
                answerCopy.push({
                    id: i.id,
                    answer_title: i.answer_title
                })
            });
            answer[1].forEach((i) => {
                userAnswers.push(questionCopy.answers[questionCopy.answers.findIndex(el => el.id === Number(i))]);
            });

            questionCopy.answers.forEach((i) => {
                answerCopy.forEach((j) => {
                    if(JSON.stringify(i) === JSON.stringify(j)) {
                        i.is_correct = true;
                    }
                })
                userAnswers.forEach((j) => {
                    if(i.id === j.id) {
                        i.answered = true;
                    }
                })
            });
        }
        console.log(questionCopy.answers);
    }

    if(pathname === `/tests/${numberTest}`) {
        return (
            question.answers.map((answer, index) =>
                <label className="multiple-answer__container" key={index}>
                    <input id={answer.id} onChange={(e) => handleMultipleAnswerClick(e, index)} checked={isChecked[index]} className="multiple-answer__input" value={answer.answer_title} name={question.id} type="checkbox"></input>
                    <span className="multiple-answer__mark"></span>
                    {answer.answer_title.split('&').map((answer) =>
                        <FormattedParagraph text={answer} position="answers"/>
                    )}
                </label>
            )
        )
    } else if (pathname === `/test-results/${numberTest}` && questionCopy.answers.length !== 0 && userAnswers) {
        return (
            <ul className="multiple-answer__result-list">
                {
                    questionCopy.answers.map((answerOption) =>
                        <li className="multiple-answer__result-item" key={answerOption.answer_title}>
                            <ResultMultipleCheckbox answerOption={answerOption} />
                            <div>
                                {answerOption.answer_title.split('&').map((answer) =>
                                    <FormattedParagraph text={answer} position="answers"/>
                                )}
                            </div>
                        </li>
                    )}
            </ul>
        )
    }
};
