export const handleBuyButton = (number, setPath) => {
    switch (number) {
        case 1:
            setPath('https://payform.ru/7j1Od8K/');
            break;
        case 2:
            setPath('https://payform.ru/9i1Od9N/');
            break;
        case 3:
            setPath('https://payform.ru/as1Odau/');
            break;
        case 4:
            setPath('https://payform.ru/dj1OdbW/');
            break;
        case 5:
            setPath('https://payform.ru/ej1Odct/');
            break;
        case 6:
            setPath('https://payform.ru/gl1Oddz/');
            break;
        case 7:
            setPath('https://payform.ru/pm1Odin/');
            break;
        case 8:
            setPath('https://payform.ru/rc1Odjh/');
            break;
        case 9:
            setPath('https://payform.ru/sf1OdjR/');
            break;
        case 10:
            setPath('https://payform.ru/831OdkY/');
            break;
        case 11:
            setPath('https://payform.ru/831Odlk/');
            break;
        case 12:
            setPath('https://payform.ru/831OdlP/');
            break;
        case 13:
            setPath('https://payform.ru/831Odmm/');
            break;
        case 14:
            setPath('https://payform.ru/3o1OdmP/');
            break;
        case 15:
            setPath('https://payform.ru/4t1Odnr/');
            break;
        case 16:
            setPath('https://payform.ru/6d1Odof/');
            break;
        case 17:
            setPath('https://payform.ru/831Ofqn/');
            break;
        case 18:
            setPath('https://payform.ru/831Ofrj/');
            break;
        case 19:
            setPath('https://payform.ru/3m1OfrP/');
            break;
        case 20:
            setPath('https://payform.ru/4i1Ofsi/');
            break;
        case 21:
            setPath('https://payform.ru/5u1Oft1/');
            break;
        case 22:
            setPath('https://payform.ru/6w1OftA/');
            break;
        case 23:
            setPath('https://payform.ru/7q1Ofu1/');
            break;
        case 24:
            setPath('https://payform.ru/8j1Ofur/');
            break;
        case 25:
            setPath('https://payform.ru/9l1Ofv0/');
            break;
        case 26:
            setPath('https://payform.ru/at1OfvF/');
            break;
        default:
            setPath('');
    }
};