import './CorrectAnswers.scss';
import React from "react";

export const CorrectAnswers = ({words}) => {
    return (
        <div className="correct-answers-block">
            <div className="correct-answers-block__title">
                <p className="correct-answers-block__title-inner">Верные ответы: </p>
            </div>
            <ul className="second-game-answers-list">
                {words.map((word) => (
                    <li className="second-game-answers-list__item">
                        <p className="second-game-answers-list__item-title">{word.answer}</p>
                        <div className="second-game-answers-list__item-box">{word.task_word}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
};