import {baseUrl} from "../../../constants/baseUrl";

async function handleResponse(res) {
    const result = await res.json();
    return res.ok ? result : Promise.reject(result);
}

export const getInitialTests = () => {
    return fetch(`${baseUrl}/v1/grade/ege/`, {
        method: 'GET',
    }).then(handleResponse);
}
