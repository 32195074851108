import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {Header} from '../../../modules/Header';
import './AccountTestsPage.scss';
import {Footer} from "../../../modules/Footer";
import {AccountEmpty} from "../../../components/AccountEmpty";
import {CourseCard} from "../../../components/CourseCard";
import {AccountPopup} from "../../../modules/AccountPopup";
import {BackButton} from "../../../ui/BackButton";

function AccountTestsPage({currentUser, setTestNumber, authorized, name, accountPopupOpened, handleAccountPopupClose, handleAccountPopupOpen, handleSignOut, setCurrentLocation}) {
    const testResults = JSON.parse(localStorage.getItem('results')) || [];
    const location = useLocation();

    useEffect(() => {
        setCurrentLocation(location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <AccountPopup currentUser={currentUser} accountPopupOpened={accountPopupOpened} handleAccountPopupClose={handleAccountPopupClose} handleSignOut={handleSignOut}/>
            <div className={!accountPopupOpened ? "account-tests" : "account-tests account-tests_popup-opened"}>
                <Header authorized={authorized} name={name} handleAccountPopupOpen={handleAccountPopupOpen}/>
                <div className="account-tests__content">
                    <BackButton path="/profile"/>
                    {
                        testResults.length === 0
                            ?
                            <AccountEmpty text="Здесь будут отображаться ваши тесты" />
                            :
                            <div className="account-tests__container">
                                {testResults.sort((a, b) => a - b).map((testNumber, index) =>
                                    <CourseCard key={index} num={testNumber} setTestNumber={setTestNumber} text="Тест по" text2="заданию" />
                                )}
                            </div>
                    }
                </div>
                <Footer />
            </div>
        </>
    );
}

export default AccountTestsPage;