import React from 'react';
import {useNavigate} from 'react-router-dom';
import './Teachers.scss';
import TeachersList from "../TeachersList/TeachersList";
import {ClosePopupButton} from "../../../../ui/ClosePopupButton";

export const Teachers = () => {
    const navigate = useNavigate();

    return (
        <div className="teachers">
            <h1 className="teachers__title">Преподаватели</h1>
            <ClosePopupButton classes="close-popup__button_absolute" handleClosePopup={() => navigate(-1)}/>
            <div className="teachers__content">
                <TeachersList />
                <div className="teachers__panda-img"/>
            </div>
        </div>
    );
}
