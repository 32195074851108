import React from 'react';
import './Login.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useEnterSubmit from '../../../hooks/useEnterSubmit';
import {
  regexEmail,
  regexPassword,
  validationEmailMessage,
  validationPasswordMessage,
} from '../../../constants/ConstantsValidation';
import {ClosePopupButton} from "../../../ui/ClosePopupButton";

export const Login = ({ handleLogin }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const navigate = useNavigate();

  const [isCheckboxActive, setIsCheckboxActive] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);

  function handleCheckbox() {
    setIsCheckboxActive(!isCheckboxActive);
  }
  function handlePasswordVisible() {
    setIsPasswordVisible(!isPasswordVisible);
  }
  function onSubmit(data) {
    handleLogin({ ...data, needRememberUser: isCheckboxActive }, false);
  }
  // настройка, чтобы при нажатии Enter работал сабмит формы
  useEnterSubmit(handleSubmit, onSubmit);

  return (
    <div className="login">
      <div className="login__container">
        <form
          className={`login__form ${errors.email && errors.password ? 'login__form_errors' : ''}`}
          name="login-form"
          onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register('email', {
              required: 'Пожалуйста, введите email',
              pattern: {
                value: regexEmail,
                message: validationEmailMessage,
              },
            })}
            type="email"
            className={`login__input ${errors.email ? 'login__input_error' : ''}`}
            placeholder="Email"
          />
          {errors.email ? <span className="login__error">{errors.email?.message}</span> : ''}
          <div className="login__input-container">
            <input
              {...register('password', {
                required: 'Пожалуйста, введите пароль',
                minLength: {
                  value: 6,
                  message: 'Пароль должен содержать минимум 6 символов',
                },
                pattern: {
                  value: regexPassword,
                  message: validationPasswordMessage,
                },
              })}
              type={`${!isPasswordVisible ? 'password' : 'text'}`}
              className={`login__input ${errors.password ? 'login__input_error' : ''}`}
              placeholder="Пароль"
            />
            {errors.password ? (
              <span className="login__error">{errors.password?.message}</span>
            ) : (
              ''
            )}
            <button
              className={`login__eye ${isPasswordVisible ? 'login__eye_close' : ''}`}
              type="button"
              onClick={handlePasswordVisible}></button>
          </div>
        </form>
        <label className="login__checkbox-info">
          <input type="checkbox" className="login__checkbox"></input>
          <span className="login__checkbox-new" onClick={handleCheckbox}></span>
          <span className="login__text" onClick={handleCheckbox}>
            Запомнить меня на этом устройстве
          </span>
        </label>
        <Link to="/restore-password" className="login__text">
          <span>Забыли пароль &#63;</span>
        </Link>
        <div className="login__save-button-container">
          <span className="login__save-button-left-lamp"></span>
          <button
            type="submit"
            className="login__save-button"
            onClick={handleSubmit(onSubmit)}
            disabled={!watch('email') || !watch('password') || Object.entries(errors).length !== 0}>
            Войти
          </button>
          <span className="login__save-button-right-lamp"></span>
        </div>
      </div>
      <ClosePopupButton classes="close-popup__button_absolute" handleClosePopup={() => navigate(-1)}/>
    </div>
  );
}
