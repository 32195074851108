import {baseUrl} from "../../../constants/baseUrl";

async function handleResponse(res) {
    const result = await res.json();
    return res.ok ? result : Promise.reject(result);
}

export function sendAnswers(data, testNumber, authorized) {
    return fetch(`${baseUrl}/v1/grade/ege/${testNumber}/check_result/`, {
        method: 'POST',
        headers: {
            authorization:
                authorized &&
                `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(handleResponse);
}