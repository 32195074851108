import React from "react";
import { ProgressBarContext } from '../../context';
import "./ProgressBar.scss";
import { useSelector } from 'react-redux'
import { getClassNames } from '../../../../../../../helpers/getClassNames';


export function ProgressBar({ className, children, steps }) {
  const classNames = getClassNames(['game-progress-bar', className]);
  const { countSteps } = useSelector((state) => state.game);
  const { pageWidth } = useSelector((state) => state.pageWidth);

  let width;

  if (pageWidth > 450) {
    width = pageWidth * 0.8;
  } else {
    width = pageWidth * 0.95;
  }

  return (
    <ProgressBarContext.Provider value={{ countSteps, steps, pageWidth }}>
      <div className={classNames} style={{ width: `${width}px` }}>
        {children}
      </div>
    </ProgressBarContext.Provider>
  );
}
