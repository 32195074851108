import React from 'react';
import './ResultsButton.scss';

const ResultsButton = ({text, className, saveResult, localTestNumber}) => {

    if (text === 'Сохранить результат') {
        return <button onClick={() => saveResult(localTestNumber)} className={className}> {text} </button>
    } else {
        return <button className={className}> {text} </button>
    }
};

export default ResultsButton;
