import React from 'react';
import {Header} from '../../../modules/Header';
import { Link } from 'react-router-dom';
import './AccountMainPage.scss';
import {Footer} from '../../../modules/Footer';
import {AccountPopup} from '../../../modules/AccountPopup';

export const AccountMainPage = ({
  currentUser,
  authorized,
  name,
  accountPopupOpened,
  handleAccountPopupClose,
  handleAccountPopupOpen,
  handleSignOut,
}) => {
  const courses = false;

  return (
    <>
      <AccountPopup
        currentUser={currentUser}
        accountPopupOpened={accountPopupOpened}
        handleAccountPopupClose={handleAccountPopupClose}
        handleSignOut={handleSignOut}
      />
      <div
        className={!accountPopupOpened ? 'account-page' : 'account-page account-page_popup-opened'}>
        <Header
          authorized={authorized}
          name={name}
          handleAccountPopupOpen={handleAccountPopupOpen}
        />
        <div className="account-page__content">
          <ul className="account-page__buttons-list">
            {courses && (
              <Link to="/profile/courses" className="account-page__link">
                <li className="account-page__button account-page__button-first">Практикум</li>
              </Link>
            )}
            <Link to="/profile/tests" className="account-page__link">
              <li className="account-page__button">Результаты тестов</li>
            </Link>
          </ul>
        </div>
        <Footer />
      </div>
    </>
  );
}
