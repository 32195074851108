import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import {PageTitle} from '../../../../ui/PageTitle';
import {PageSubtitle} from '../../../../ui/PageSubtitle';
import Quiz from './../../components/Quiz/Quiz';
import Progress from './../../components/Progress/Progress';
import {Loader} from '../../../../ui/Loader';
import './TestsContainer.scss';
import {BackButton} from '../../../../ui/BackButton';
import Api from "../../../../utils/Api";

function TestsContainer () {
    const {numberTest} = useParams();
    const [quizQuestions, setQuizQuestions] = useState({});
    const [question, setQuestion] = useState({});
    const [step, setStep] = useState(0);
    const [answer, setAnswer] = useState([]); // сюда сохраняем текущий выбор в инпуте
    const [result, setResult] = useState({}); // сюда сохраняем промежуточный результат
    const [isChecked, setIsChecked] = useState(new Array(20).fill(false)); // результат ввода инпута в вопросе типа Multiple
    const [selectedOption, setSelectedOption] = useState(''); // результат ввода инпута в вопросе типа Single
    const [input, setInput] = useState(''); // результат ввода инпута в вопросе типа Insertion
    // результаты ввода инпутов в вопросе типа Correspond
    const [correspondInput1, setCorrespondInput1] = useState('');
    const [correspondInput2, setCorrespondInput2] = useState('');
    const [correspondInput3, setCorrespondInput3] = useState('');
    const [correspondInput4, setCorrespondInput4] = useState('');
    const [correspondInput5, setCorrespondInput5] = useState('');
    const answers = JSON.parse(localStorage.getItem(`answers_${numberTest}`)) || {};

    const [array, setArray] = useState(true); // визуальное отображение вопросов на бегунке

    useEffect(() => {
        Api.getInitialQuestions(numberTest)
            .then((data) => {
                setQuizQuestions(data);
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (quizQuestions.questions) {
            setQuestion(quizQuestions.questions[step]);
        }
        if (Object.keys(answers).length !== 0) {
            setResult(answers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizQuestions, step]);

    // функция для мини-пагинации на бегунке
    const handleDotsButton = () => {
        setArray(!array);
    };

    useEffect(() => {
        if (Object.keys(answers).length >= 20) {
            setArray(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetAnswer = (questionOption) => {
        if (questionOption.question_type === 'insertion') {
            setInput('');
        } else if (questionOption.question_type === 'single') {
            setSelectedOption('');
        } else if (questionOption.question_type === 'multiple') {
            const updatedChecked = isChecked.fill(false);
            setIsChecked(updatedChecked);
        } else if (questionOption.question_type === 'correspond') {
            setCorrespondInput1('');
            setCorrespondInput2('');
            setCorrespondInput3('');
            setCorrespondInput4('');
            setCorrespondInput5('');
        }
    };

    const retrieveAnswer = (questionOption) => {
        setAnswer(answers[questionOption.id]);
        if (questionOption.question_type === 'insertion') {
            setInput(answers[questionOption.id]);
        } else if (questionOption.question_type === 'single') {
            setSelectedOption(
                questionOption.answers[
                    questionOption.answers.findIndex((el) => el.id === Number(answers[questionOption.id][0]))
                    ]['answer_title'],
            );
        } else if (questionOption.question_type === 'multiple') {
            const updatedChecked = isChecked.fill(false);
            answers[questionOption.id].forEach((i, number) => {
                let index = questionOption.answers.findIndex(
                    (el) => el.id === Number(answers[questionOption.id][number]),
                );
                updatedChecked.splice(index, 1, i);
            });
            setIsChecked(updatedChecked);
        } else if (questionOption.question_type === 'correspond') {
            setCorrespondInput1(answers[questionOption.id][0]['А']);
            setCorrespondInput2(answers[questionOption.id][0]['Б']);
            setCorrespondInput3(answers[questionOption.id][0]['В']);
            setCorrespondInput4(answers[questionOption.id][0]['Г']);
            setCorrespondInput5(answers[questionOption.id][0]['Д']);
        }
    };

    // функция сохранения ответы в localStorage
    const saveDataToLocalStorage = () => {
        const data = {
            [question.id]: answer,
        };
        if (data[question.id].length) {
            let copy = Object.assign(result, data);
            setResult(copy);
            localStorage.setItem(`answers_${numberTest}`, JSON.stringify(copy));
            localStorage.setItem(`answersProfile_${numberTest}`, JSON.stringify(copy));
        }
    };

    const handleFinalTestButton = () => {
        saveDataToLocalStorage();
        let updatedArray = JSON.parse(localStorage.getItem('tests-completed')) || new Array(28).fill(false);
        if (Object.keys(updatedArray).length !== 0) {
            updatedArray.splice(numberTest - 1, 1, true);
            localStorage.setItem('tests-completed', JSON.stringify(updatedArray));
            localStorage.removeItem(`answers_${numberTest}`);
        }
    };

    // функция нажатия на кнопку Дальше
    const onHandleButtonClick = () => {
        const nextQuestion = quizQuestions.questions[step + 1];
        const currentQuestion = quizQuestions.questions[step];
        if (quizQuestions.questions[20]) {
            setArray(false);
        }
        // проверяем, есть ли в следующем вопросе какой-то ответ
        if (!answers[nextQuestion.id]) {
            resetAnswer(nextQuestion);
        } else {
            retrieveAnswer(nextQuestion);
        }
        if (!answers[currentQuestion.id] || answer !== answers[currentQuestion.id]) {
            saveDataToLocalStorage();
        }
        setStep(step + 1);
        setAnswer([]);
    };

    // функция нажатия на кнопку Назад

    const onHandleButtonBackClick = () => {
        const previousQuestion = quizQuestions.questions[step - 1];
        // проверяем, есть ли в предыдущем вопросе какой-то ответ
        if (!answers[previousQuestion.id]) {
            resetAnswer(previousQuestion);
        } else {
            retrieveAnswer(previousQuestion);
        }
        setStep(step - 1);
    };

    // функции обработки результатов ввода в инпуты (кроме Correspond)
    const onHandleSingleAnswerClick = (e) => {
        const index = e.target.id;
        const arr = [index];
        setAnswer(arr);
        setSelectedOption(e.target.value);
    };

    const onHandleInsertInput = (e) => {
        const inputValue = e.target.value;
        setInput(inputValue.replace(/ /g,''));
        setAnswer(inputValue.replace(/ /g,'').split(' '));
    };

    const onHandleMultipleAnswerClick = (e, position) => {
        const updatedCheckedState = isChecked.map((item, index) => (index === position ? !item : item));
        let resultArr = [];
        question.answers.forEach((i, index) => {
            if (updatedCheckedState[index]) {
                resultArr.push(String(i.id));
            }
        });
        setAnswer(resultArr);
        setIsChecked(updatedCheckedState);
    };

    return (
        <div className="tests__content">
            <BackButton path="/ege" />
            <PageTitle text="Русский язык" className="page-title tests__content-title" />
            {!quizQuestions.questions
                ?
                <Loader />
                :
                <>
                    <PageSubtitle text="задание" testNumber={numberTest} />
                    <Progress
                        quizQuestions={quizQuestions}
                        result={result}
                        answers={answers}
                        setStep={setStep}
                        retrieveAnswer={retrieveAnswer}
                        resetAnswer={resetAnswer}
                        array={array}
                        handleButton={handleDotsButton}
                    />
                    {step !== quizQuestions.questions.length && (
                        <Quiz
                            quizQuestions={quizQuestions}
                            step={step}
                            isChecked={isChecked}
                            setIsChecked={(value) => setIsChecked(value)}
                            question={question}
                            selectedOption={selectedOption}
                            handleButtonClick={onHandleButtonClick}
                            handleButtonBackClick={onHandleButtonBackClick}
                            handleSingleAnswerClick={onHandleSingleAnswerClick}
                            handleMultipleAnswerClick={onHandleMultipleAnswerClick}
                            handleInsertInput={onHandleInsertInput}
                            input={input}
                            setAnswer={(value) => setAnswer(value)}
                            correspondInput1={correspondInput1}
                            correspondInput2={correspondInput2}
                            correspondInput3={correspondInput3}
                            correspondInput4={correspondInput4}
                            correspondInput5={correspondInput5}
                            setCorrespondInput1={(value) => setCorrespondInput1(value)}
                            setCorrespondInput2={(value) => setCorrespondInput2(value)}
                            setCorrespondInput3={(value) => setCorrespondInput3(value)}
                            setCorrespondInput4={(value) => setCorrespondInput4(value)}
                            setCorrespondInput5={(value) => setCorrespondInput5(value)}
                            handleFinalButtonClick={handleFinalTestButton}
                        />
                    )}
                </>
            }
        </div>
    );
}
export default TestsContainer;
