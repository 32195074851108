import React from 'react';
import './EgePage.scss';
import {Header} from '../../../../modules/Header';
import {Footer} from '../../../../modules/Footer';
import {PageTitle} from '../../../../ui/PageTitle';
import {PageDescription} from '../../../../ui/PageDescription';
import {EgeTasksList} from "../EgeTasksList/EgeTasksList";
import {text, textNotAuth} from "../../constants/PageDescText";

export const EgePage = ({ authorized, name }) => {

  return (
    <>
        <Header authorized={authorized} name={name} />
        <div className="main">
          <PageTitle text="Русский язык ЕГЭ" className="page-title main__title ege__title" />
          <PageDescription
            text={text}
            className={`${authorized ? 'main__description' : 'ege__description'}`}
          />
          {!authorized ? (
            <PageDescription text={textNotAuth} className="ege__description-auth" />
          ) : (
            ''
          )}
          <EgeTasksList authorized={authorized}/>
        </div>
        <Footer />
    </>
  );
}
