import React from 'react';
import {useNavigate} from 'react-router-dom'
import './CourseCard.scss';

export const CourseCard = ({text, text2, num, disabled, info}) => {
    const navigate = useNavigate();

    let classes = 'course-card';

    if(disabled) {
        classes += ' course-card_disabled';
    }

    const openTestResults = () => {
        navigate(`/test-results/${num}`);
    }

    return (
        <div onClick={openTestResults} className={classes}>
            <h5 className="course-card__title">
                {text} {num} {text2}
            </h5>
            <p className="course-card__subtitle">{info}</p>
        </div>
    );
}
