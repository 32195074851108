import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.scss';

export const BackButton = ({path}) => {

    const navigate = useNavigate();

    return (
            <button onClick={() => navigate(path)} className="back-button">
            </button>

    );
}