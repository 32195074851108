import { useState, useEffect } from 'react';
import "./Countdown.scss";

const Countdown = ({onCountEnd}) => {
    const [count, setCount] = useState(3);
    const classes = `countdown count-${count}`

    useEffect(() => {
        if (count > 0) {
            setTimeout(() => {
                setCount(count - 1);
            }, 1000);
        } else {
            setTimeout(() => {
                onCountEnd();
            }, 1000);
        }
    }, [count, onCountEnd]);

    return (
        <>{count 
            ? <div className={classes}></div>
            : null
        }
        </>
    );
}

export default Countdown;