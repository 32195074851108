import React from 'react';
import egeButtons from '../../images/egeButtons.svg';

function EgeButtonImg({ number, fill, stroke }) {
  return (
    <svg
      className="ege__svg"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2px"
      stroke={stroke}>
      <use href={egeButtons + `#button-${number}`} />
    </svg>
  );
}

export default EgeButtonImg;
