import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import './SingleAnswer.scss';
import {FormattedParagraph} from "../../../ui/FormattedParagraph";

export const SingleAnswer = ({selectedOption, answer, question, handleSingleAnswerClick}) => {
    const {pathname} = useLocation();
    const answerCopy = [];
    let questionCopy = {};
    const userAnswers = [];
    const {numberTest} = useParams();


    if(pathname === `/test-results/${numberTest}`) {
        if(question.answers && answer) {
            Object.assign(questionCopy, question);
            answer[0].forEach((i) => {
                answerCopy.push({
                    id: i.id,
                    answer_title: i.answer_title
                })
            });
            answer[1].forEach((i) => {
                userAnswers.push(questionCopy.answers[questionCopy.answers.findIndex(el => el.id === Number(i))]);
            });

            questionCopy.answers.forEach((i) => {
                answerCopy.forEach((j) => {
                    if(JSON.stringify(i) === JSON.stringify(j)) {
                        i.is_correct = true;
                    }
                })
                userAnswers.forEach((j) => {
                    if(i.id === j.id) {
                        i.answered = true;
                    }
                })
            });
        }
    }

    if(pathname === `/tests/${numberTest}`) {
        return (
            question.answers.map((answer, index) =>
                <label htmlFor={answer.id} className="single-answer__container" key={index}>
                    <input id={answer.id} onChange={(e) => handleSingleAnswerClick(e)} checked={selectedOption === answer.answer_title} className="single-answer__input" value={answer.answer_title} name={question.id} type="radio"></input>
                    <span className="single-answer__mark"></span>
                    {answer.answer_title.split('&').map((answer) =>
                        <FormattedParagraph text={answer} position="answers"/>
                    )}
                </label>
            )
        )
    } else if (pathname === `/test-results/${numberTest}` && userAnswers && questionCopy.answers.length !== 0) {
        return (
                <ul className="single-answer__result-list">
                    {
                        questionCopy.answers.map((answerOption) =>
                            <li className="single-answer__result-item" key={answerOption.answer_title}>
                                <div className="single-answer__result-circle">
                                    {answerOption.answered && !answerOption.is_correct ?
                                        <div className="single-answer__result-circle-inner single-answer__result-circle-inner_wrong"></div>
                                        :
                                        <div className={(answerOption.is_correct && answerOption.answered) || answerOption.is_correct ? "single-answer__result-circle-inner single-answer__result-circle-inner_correct" : "single-answer__result-circle-inner"}></div>
                                    }
                                </div>
                                <div>
                                    {answerOption.answer_title.split('&').map((answer, index) =>
                                        <FormattedParagraph text={answer} position="answers"/>
                                    )}
                                </div>
                            </li>
                        )
                    }
                </ul>
        )
    }
};

