import React from 'react';
import './Carousel.scss';
import {SingleAnswer} from '../../../../components/SingleAnswer';
import {MultipleAnswer} from '../../../../components/MultipleAnswer';
import {InsertInput} from '../../../../components/InsertInput';
import {CorrespondTest} from '../../../../components/CorrespondTest';
import {FormattedParagraph} from "../../../../ui/FormattedParagraph";

const Carousel = ({
  checkedAnswers,
  answer,
  question,
  questions,
  step,
  handleForwardButton,
  handleBackButton,
}) => {
  let questionsLength = '';
  if (questions && questions.length !== 0) {
    questionsLength = questions.length;
  }

  return (
    <div className="carousel">
        <div className="carousel__buttons-area carousel__buttons-area_visible-1400px">
            <button
                className={
                    step === 0
                        ? 'carousel__button carousel__button_back hidden'
                        : 'carousel__button carousel__button_back'
                }
                onClick={() => handleBackButton()}>
            </button>
            <button
                className={
                    step === questionsLength - 1
                        ? 'carousel__button carousel__button_forward hidden'
                        : 'carousel__button carousel__button_forward'
                }
                onClick={() => handleForwardButton()}></button>
        </div>
        <button
        className={
          step === 0
            ? 'carousel__button carousel__button_hidden-1440px carousel__button_back hidden-button'
            : 'carousel__button carousel__button_hidden-1440px carousel__button_back'
        }
        onClick={() => handleBackButton()}></button>
          {
              question.question_type !== 'correspond' ?
                  <div className="carousel__container">
                      <h3 className="question-number">Вопрос {step + 1}</h3>
                      <h3 className="quiz__title">{question.question_title}</h3>
                      {question.question_description &&
                          question.question_description.split('&').map((desc) =>
                              <FormattedParagraph text={desc} position="description"/>
                          )
                      }
                      {question.question_type === 'single' && (
                          <SingleAnswer
                              answer={answer}
                              question={question}
                              step={step}
                          />
                      )}
                      {question.question_type === 'multiple' && (
                          <MultipleAnswer
                              answer={answer}
                              question={question}
                              step={step}
                          />
                      )}
                      {question.question_type === 'insertion' && (
                          <InsertInput
                              question={question}
                              answer={answer}
                              step={step}
                              checkedAnswers={checkedAnswers}
                          />
                      )}
                  </div>
                  :
                  <CorrespondTest
                      question={question}
                      step={step}
                      answer={answer}
                      checkedAnswers={checkedAnswers}
                  />
          }
      <button
        className={
          step === questionsLength - 1
            ? 'carousel__button carousel__button_hidden-1440px carousel__button_forward hidden-button'
            : 'carousel__button carousel__button_hidden-1440px carousel__button_forward'
        }
        onClick={() => handleForwardButton()}></button>
    </div>
  );
};

export default Carousel;
