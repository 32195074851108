import { useState, useEffect, useCallback } from "react";
import {ProgressBar} from "../../../components/progress-bar";
import Results from "../Results/Results";
import StartBtn from "../StartBtn/StartBtn";
import Pause from "../Pause/Pause";
import Countdown from "../Countdown/Countdown";
import Answer from "../Answer/Answer";
import {getGameDetails} from "../../api/Api";
import { useParams } from "react-router-dom";
import {useDispatch} from "react-redux";
import {nextStep} from "../../../store/gameSlice";
import "./ThirdGame.scss";
import { PauseButton } from "../../../components/progress-bar/components/PauseButton/PauseButton";

export function ThirdGame() {
    const { gameNumber } = useParams();
    const [ isGameStarted, setIsGameStarted ] = useState(false);
    const [ isGameFinished, setIsGameFinished ] = useState(false);
    const [ isGamePaused, setIsGamePaused ] = useState(false);
    const [ isAnswerCorrect, setIsAnswerCorrect ] = useState(false);
    const [ game, setGame ] = useState({});
    const [ words, setWords ] = useState([]);
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ animationStyle, setAnimationStyle ] = useState({});
    const [ correctAnswers, setCorrectAnswers ] = useState([]);
    const [ wrongAnswersCount, setWrongAnswersCount ] = useState(0);
    const [ isCounterShowing, setIsCounterShowing ] = useState(false);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (gameNumber) {
            getGameData(gameNumber);
        }
    }, [gameNumber]);

    useEffect(() => {

        if (isAnswerCorrect) {
            setCorrectAnswers([...correctAnswers, currentIndex - 1]);
            console.log(currentIndex);
        }

        if (isGamePaused) {
            return;
        }

        if (currentIndex < words.length) {
          const animationDelay = game.duration * 1000;
          setAnimationStyle({
            animation: `moveRight ${game.duration}s ease-in-out infinite`,
          });

          const timeoutId = setTimeout(() => {
            
            setCurrentIndex(currentIndex + 1);
            dispatch(nextStep());
          }, animationDelay);

          return () => clearTimeout(timeoutId);
        } else if (currentIndex) { 
            setWrongAnswersCount(game.steps - correctAnswers.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentIndex, words, game.duration, isGamePaused]);

      const startCounter = () => {
        setIsCounterShowing(true);
      }
    
      const startGame = useCallback(() => {
        if (game.tasks.length > 0) {
            setIsGameStarted(true);
            setWords(game.tasks.map(task => task.task_word.toUpperCase()));
        }
      }, [game]);

      const handlePause = () => {
        setIsGamePaused(true);
        console.log(isGamePaused);
      }

      const handleResumeGame = () => {
        setIsGamePaused(false);
        setCurrentIndex(currentIndex + 1);
        dispatch(nextStep());
      }

    async function getGameData(number) {
        try {
            const response = await getGameDetails(number);
            if (response.status === 200) {
                setGame({
                    description: response.data.description,
                    duration: response.data.game_duration,
                    tasks: response.data.tasks,
                    steps: response.data.tasks.length
                })
            }
            return response;
        } catch (error) {
            console.log(error);
        }
    }

    
    const renderWords = () => {
        if (currentIndex < words.length) {
            return <div className="word" style={animationStyle}>{words[currentIndex]}</div>;
        } else {
            setIsGameStarted(false);
            setIsGameFinished(true);
            return;
        }
    };
   
    return (
        <div className="game">
            {isCounterShowing ? <Countdown onCountEnd={startGame}/> : null}
            <ProgressBar game="three" steps={game.steps} wrongAnswers={wrongAnswersCount} />

            {isGameStarted
                ? isGamePaused
                    ? <Pause isOpen={true} onClose={handleResumeGame} />
                    : <>
                        <div className="game__content">
                            <h2 className="game__title">{game.description}</h2>
                            <div className="word-wrapper">
                                {renderWords()} 
                            </div>
                            <Answer correct={game.tasks[currentIndex]?.correct_letter} incorrect={game.tasks[currentIndex]?.other_letter} isAnswerCorrect={setIsAnswerCorrect} currentIndex={currentIndex}/>
                    
                        </div>
                        <PauseButton pauseBtnCallback={handlePause} />
                    </>
                
                : isGameFinished
                    ? <Results game={game} correctAnswers={correctAnswers} />
                    : <StartBtn isOpen={true} onClose={startCounter} />
            }
        </div>
    )
}

