import React, {useState, useEffect} from 'react';
import Lamp from '../../images/practicum-lamp.svg';
import Star from '../../images/practicum-star.svg';
import './PracticumCover.scss';

function PracticumCover({taskNumber}) {
    const [classesCover, setClassesCover] = useState('');
    const [classesSubtitle, setClassesSubtitle] = useState('');
    const [subtitle, setSubtitle] = useState('');

    useEffect(() => {
        initialCover();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskNumber]);

    const initialCover = () => {
        if (taskNumber >= 1 && taskNumber <= 3) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_1-8");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_1-8");
            setSubtitle('ПО ТЕКСТУ');
        } else if (taskNumber === 4) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_1-8");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_1-8");
            setSubtitle('УДАРЕНИЯ');
        } else if (taskNumber >= 5 && taskNumber <= 6) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_1-8");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_1-8 buy-practicum__cover-subtitle_1-8-speech");
            setSubtitle('РЕЧЕВЫЕ ОШИБКИ');
        } else if (taskNumber >= 7 && taskNumber <= 8) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_1-8");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_1-8");
            setSubtitle('ГРАММАТИКА');
        } else if (taskNumber >= 9 && taskNumber <= 12) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_9-12");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_9-12");
            setSubtitle('ОРФОГРАФИЯ');
        } else if (taskNumber >= 13 && taskNumber <= 14) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_13-21");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_13-21");
            setSubtitle('ОРФОГРАФИЯ');
        } else if (taskNumber >= 15 && taskNumber <= 21) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_13-21");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_13-21");
            setSubtitle('ПУНКТУАЦИЯ');
        } else if (taskNumber >= 22 && taskNumber <= 26) {
            setClassesCover("buy-practicum__cover buy-practicum__cover_22-26");
            setClassesSubtitle("buy-practicum__cover-subtitle buy-practicum__cover-subtitle_22-26");
            setSubtitle('ПО ТЕКСТУ');
        }
    }

    return (
        <div className={classesCover}>
            <h3 className="buy-practicum__cover-title">{taskNumber} задание</h3>
            <h4 className={classesSubtitle}>{subtitle}</h4>
            <img alt="картинка лампочки" src={Lamp} className="buy-practicum__cover-img buy-practicum__cover-img_lamp" />
            <img alt="картинка звезды" src={Star} className="buy-practicum__cover-img buy-practicum__cover-img_star" />
        </div>
    )
}

export default PracticumCover;
