import React from 'react';
import './ErrorPopup.scss';
import { useEscClose, useClickClose } from '../../../hooks/UseClose';

export const ErrorPopup = ({ isOpen, error, onClose }) => {
  useEscClose(isOpen, onClose);
  useClickClose(isOpen, onClose, 'error-popup__container');

  return (
    <div className={`error-popup ${isOpen ? 'error-popup_opened' : ''}`}>
      <div className="error-popup__container">
        <div className="error-popup__error-container">
          {error
            ? error.map((i, key) => (
                <p className="error-popup__text" key={key}>
                  {i}
                </p>
              ))
            : ''}
          <span className="error-popup__lamps"></span>
          <span className="error-popup__panda"></span>
        </div>
        <button type="button" className="registration__close-button" onClick={onClose}></button>
      </div>
    </div>
  );
}
