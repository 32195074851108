import {useState} from 'react';

export function useRandomImage (id) {
    const [img, setImg] = useState('');
    const [buttonClass, setButtonClass] = useState([]);
    const getRandomBackground = () => {
        let num = Math.floor(Math.random() * 6);

        if(num === 0) {
            setImg('background-1');
        } else if(num === 1) {
            setImg('background-2');
        } else if(num === 2) {
            setImg('background-3');
        } else if(num === 3) {
            setImg('background-4');
        } else if(num === 4) {
            setImg('background-5');
        } else if(num === 5) {
            setImg('background-6');
        }
    }

    const getRandomPanda = (index) => {
        let i = index + 1;
        if (i === 1 || i % 5 === 0) {
            setButtonClass(['test-button test-button__panda']);
        } else {
            setButtonClass(['test-button']);
        }
    }

    return [img, buttonClass, getRandomBackground, getRandomPanda];
}