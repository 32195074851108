import React from "react";
import { getClassNames } from '../../../../../helpers/getClassNames';
import './GameButton.scss';

const GameButton = ({ onClick, children, className, disabled, imgProps, size = 'md' }) => {
  const btnClassNames = getClassNames(['game-button', className]);
	const imgClassNames = getClassNames(['game-button__img', imgProps?.className]);

	return (
		<button
			className={btnClassNames}
			onClick={onClick}
			type="button"
			disabled={disabled}
			data-size={size}
		>
			{imgProps?.src && <img src={imgProps.src} alt="" className={imgClassNames} />}
			{children}
		</button>
	);
};

export default GameButton;