import React from 'react';
import './FormattedParagraph.scss';

export const FormattedParagraph = ({text, position}) => {
    return (
        <p key={text} className={position === 'description' ? "text" : position === 'answers' ? "text-answers" : ""}>
            {
                text.split(' ').map((word) =>
                    word.includes('$') || word.includes('#')
                        ?
                       ( word.includes('$') && <span key={Math.random()} className="italic">{word.slice(1) + ' '}</span>)
                        || (word.includes('#') && <span key={Math.random()} className="bold">{word.slice(1) + ' '}</span>)
                        :
                        word + ' '
                )
            }
        </p>
    );
};