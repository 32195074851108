import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tilt from 'react-parallax-tilt';

import { useEscClose, useClickClose } from '../../../hooks/UseClose';
import './Header.scss';
import useWindowDimensions from '../../../helpers/screenSizeDetector';
import {
  breackPointHeaderOne,
  breackPointHeaderTwo,
  breackPointHeaderThree,
  breackPointHeaderFour,
} from '../../../constants/ConstantsValidation';

export const Header = ({ authorized, name, handleAccountPopupOpen }) => {
  const titles = [
    { name: 'Главная', path: '/' },
    { name: 'О нас', path: '/about' },
    { name: 'Преподаватели', path: '/teachers' },
    { name: 'Отзывы', path: '/reviews' },
    { name: 'Все курсы', path: '/all-curses' },
  ];

  const [isInputAvailable, setIsInputAvailable] = React.useState(false);
  const [isBurgerOpen, setIsBurgerOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const location = useLocation();
  const windowWidth = useWindowDimensions().width;
  console.log(isBurgerOpen);

  const handleCloseInput = () => {
    setIsInputAvailable(false);
    setSearchValue('');
  };
  const handleCloseBurger = () => {
    setIsBurgerOpen(false);
  };

  const handleAccountPopup = () => {
    handleAccountPopupOpen();
    setIsBurgerOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEscClose(isInputAvailable, handleCloseInput);
  useEscClose(isBurgerOpen, handleCloseBurger);
  useClickClose(isInputAvailable, handleCloseInput, 'header__auth-search');
  useClickClose(
    isBurgerOpen,
    handleCloseBurger,
    'header__nav-burger_open',
    'header__burger_open',
    'header__auth-profile-button',
    'header__auth-buttons-burger',
  );

  return (
    <section className="header">
      <span className={`header__overlay ${!isBurgerOpen ? 'header_hiden' : ''}`}></span>
      <div
        className={`header__info ${
          isInputAvailable && windowWidth < breackPointHeaderFour ? 'header_hiden' : ''
        }`}>
        <Tilt>
          <Link to="/">
            <div className="header__info-logo"></div>
          </Link>
        </Tilt>
        <span
          className={`header__info-text ${
            windowWidth < breackPointHeaderOne &&
            (windowWidth < breackPointHeaderFour ||
              windowWidth > breackPointHeaderThree ||
              isInputAvailable)
              ? 'header_hiden'
              : ''
          }`}>
          Школа подготовки к ЕГЭ "Дай&nbsp;пять&#x21;"
        </span>
      </div>
      <ul
        className={`header__nav  ${isInputAvailable ? 'header__nav_reset' : ''} ${
          (isInputAvailable && windowWidth < breackPointHeaderTwo) ||
          windowWidth < breackPointHeaderThree
            ? 'header__nav-burger'
            : ''
        } ${isBurgerOpen ? 'header__nav-burger_open' : ''}`}>
        {location.pathname === '/welcome-page' ||
        location.pathname === '/profile' ||
        location.pathname === '/profile/tests' ||
        location.pathname === '/profile/courses' ? (
          <Link
            className={`header__nav-item ${
              location.pathname === titles[0].path ? 'header__nav-item_active' : ''
            }`}
            to={titles[0].path}>
            {titles[0].name}
          </Link>
        ) : (
          titles.map((i, key) => (
            <Link
              key={key}
              className={`header__nav-item ${
                location.pathname === i.path ? 'header__nav-item_active' : ''
              }`}
              to={i.path}>
              {i.name}
            </Link>
          ))
        )}
      </ul>
      <div
        className={`header__auth ${
          isInputAvailable && windowWidth < breackPointHeaderFour ? 'header__auth_full' : ''
        }`}>
        <div className="header__auth-search">
          <button
            className={`header__auth-search-button ${
              isInputAvailable ? 'header__auth-search-area_disable' : ''
            }`}
            onClick={() => {
              setIsInputAvailable(!isInputAvailable);
            }}></button>
          <form
            className={`header__auth-search-area ${
              !isInputAvailable ? 'header__auth-search-area_disable' : ''
            }`}
            onSubmit={handleSubmit}>
            <button className="header__auth-search-button_small"></button>
            <input
              className="header__auth-search-input"
              placeholder="Поиск по сайту"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}></input>
          </form>
        </div>
        {!authorized ? (
          <div
            className={`header__auth-buttons ${
              (isInputAvailable && windowWidth < breackPointHeaderTwo) ||
              windowWidth < breackPointHeaderThree
                ? 'header__auth-buttons-burger'
                : ''
            } ${isBurgerOpen ? 'header__auth-buttons-burger_open' : ''}`}>
            <Link className="header__auth-link" to="/sign-in">
              <button className="header__auth-button">Войти</button>
            </Link>
            <Link className="header__auth-link" to="/sign-up">
              <button className="header__auth-button">Регистрация</button>
            </Link>
          </div>
        ) : (
          <div
            className={`header__auth-profile-buttons ${
              isInputAvailable ? 'header__auth-profile-buttons_small' : ''
            } ${
              (isInputAvailable && windowWidth < breackPointHeaderTwo) ||
              windowWidth < breackPointHeaderThree
                ? 'header__auth-profile-buttons-burger'
                : ''
            }`}>
            <span className="header__auth-name">{name}</span>
            {location.pathname === '/profile' ||
            location.pathname === '/profile/tests' ||
            location.pathname === '/profile/courses' ||
            location.pathname === '/welcome-page' ? (
              <button
                onClick={handleAccountPopup}
                className={`header__auth-profile-button ${
                  (isInputAvailable && windowWidth < breackPointHeaderTwo) ||
                  windowWidth < breackPointHeaderThree
                    ? 'header__auth-profile-button-burger'
                    : ''
                } ${isBurgerOpen ? 'header__auth-profile-button-burger_open' : ''}`}></button>
            ) : (
              <Link to="/profile">
                <button
                  className={`header__auth-profile-button ${
                    (isInputAvailable && windowWidth < breackPointHeaderTwo) ||
                    windowWidth < breackPointHeaderThree
                      ? 'header__auth-profile-button-burger'
                      : ''
                  } ${isBurgerOpen ? 'header__auth-profile-button-burger_open' : ''}`}></button>
              </Link>
            )}
          </div>
        )}
        <button
          className={`header__burger ${
            isInputAvailable && windowWidth < breackPointHeaderTwo ? 'header_visible' : ''
          } ${isBurgerOpen ? 'header__burger_open' : ''}`}
          onClick={() => setIsBurgerOpen(!isBurgerOpen)}>
          <span
            className={`header__burger-string ${
              isBurgerOpen ? 'header__burger-string_open' : ''
            }`}></span>
          <span
            className={`header__burger-string ${
              isBurgerOpen ? 'header__burger-string_open' : ''
            }`}></span>
          <span
            className={`header__burger-string ${
              isBurgerOpen ? 'header__burger-string_open' : ''
            }`}></span>
        </button>
        {isBurgerOpen ? <div className="header__burger-container"></div> : ''}
      </div>
    </section>
  );
}
