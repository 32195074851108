import React from 'react';
import './ResultsTitle.scss';

const ResultsTitle = ({checkedAnswers, taskNumber}) => {

    if (checkedAnswers.victory_text && checkedAnswers.victory_text.split(' ')[2] === 'прекрасно') {
        return (
            <div className="test-results__title">
                <h1 className="test-results__title-congrats">Поздравляем!</h1>
                <h2 className="test-results__title-congrats"><span className="test-results__title_highlighted test-results__title_highlighted-green">Ты прекрасно ориентируешься</span> в {taskNumber} задании!</h2>
            </div>
        )
    } else if (checkedAnswers.victory_text && checkedAnswers.victory_text.split(' ')[2] === 'неплохо') {
        return (
            <div className="test-results__title">
                <h1 className="test-results__title-congrats">Поздравляем!</h1>
                <h2 className="test-results__title-congrats"><span className="test-results__title_highlighted test-results__title_highlighted-green">Ты неплохо ориентируешься</span> в {taskNumber} задании!</h2>
            </div>
        )
    } else if (checkedAnswers.victory_text && checkedAnswers.victory_text.split(' ')[2] === 'пока') {
        return (
            <div className="test-results__title">
                <h1 className="test-results__title-congrats">Уф.</h1>
                <h2 className="test-results__title-congrats"><span className="test-results__title_highlighted test-results__title_highlighted-orange">Ты пока не очень хорошо</span> ориентируешься в {taskNumber} задании.</h2>
            </div>
        )
    }
};

export default ResultsTitle;
