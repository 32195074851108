import React from 'react';

function useEnterSubmit(handleSubmit, onSubmit) {
  React.useEffect(() => {
    function handleEnter(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }
    }
    document.addEventListener('keydown', handleEnter);

    return () => document.removeEventListener('keydown', handleEnter);
  }, [handleSubmit, onSubmit]);
}

export default useEnterSubmit;
