import React from "react";
//import back from "../../first-game/images/backBtn.svg";
import "./progressBar.scss";
import {Counter} from "../Counter/Counter";
import {StepsList} from "../StepsList/StepsList";
import {useSelector} from "react-redux";

export function ProgressBar({ game, steps, wrongAnswers }) {

  let progressBar = 'progress-bar';
  const screenWidth = useSelector((state) => state.pageWidth);
  let progressBarWidth;

  if (screenWidth.pageWidth > 450) {
    progressBarWidth = `${screenWidth.pageWidth * 0.8}px`;
  } else {
    progressBarWidth = `${screenWidth.pageWidth * 0.95}px`;
  }

  switch (game) {
    case "one":
      progressBar += ' progress-bar--one';
      break;
    case "two":
      progressBar += ' progress-bar--two';
      break;
    case "three":
      progressBar += ' progress-bar--three';
      break;
    case "four":
      progressBar += ' progress-bar--four';
      break;
    default:
      progressBar = 'progress-bar'
  }

	return (
    <div className={progressBar} style={{width: progressBarWidth, maxWidth: "1200px"}}>
			<Counter steps={steps} game={game} />
            <StepsList steps={steps} game={game} wrongAnswers={wrongAnswers}/>
			{/* <button className='progress-bar__back-btn' type="button" onClick={() => navigate(-1)}>
				<img src={back} alt="back" className="progress-bar__back-image" />
			</button> */}
    </div>
		
  );
}
