import "./Results.scss";

const Results = ({game, correctAnswers}) => {
    const answers = game.tasks.map((task, i) => {
        return task.task_word.split("...").join(correctAnswers.includes(i) ? task.correct_letter : task.other_letter).toUpperCase();
    });

    return (
        <div className="results">
            <div className="results__count">Правильных ответов: {correctAnswers.length}. Неправильных ответов: {game.steps - correctAnswers.length}</div>
            <ul className="answers-list">
                {answers.map((answer, i) => {
                    if (correctAnswers.includes(i)){
                        return <li className="answers-list__answer answer-correct" key={i}><span>{answer}</span></li>
                    } else {
                        return <li className="answers-list__answer answer-incorrect" key={i}><span>{answer}</span></li>
                    }
                    
                })}
            </ul>
            <button className="button" onClick={() => window.location.reload()}>Начать заново</button>
        </div>
    )
}

export default Results;