import React from 'react';
import {Header} from '../../../modules/Header';
import './AccountCoursesPage.scss';
import {Footer} from "../../../modules/Footer";
import {AccountEmpty} from "../../../components/AccountEmpty";
import {CourseCard} from "../../../components/CourseCard";
import {AccountPopup} from "../../../modules/AccountPopup";

function AccountCoursesPage({currentUser, authorized, name, accountPopupOpened, handleAccountPopupClose, handleAccountPopupOpen, handleSignOut}) {
    const courses = false;

    const coursesObj = {
        courses: [
            {testNumber: 1, disabled: true, info: '10 апреля здесь будет твой Практикум 🤗'},
            {testNumber: 8, disabled: false, info: ''},
            {testNumber: 22, disabled: true, info: '12 июня здесь будет твой Практикум 🤗'}
        ]
    }

    return (
        <>
            <AccountPopup currentUser={currentUser} accountPopupOpened={accountPopupOpened} handleAccountPopupClose={handleAccountPopupClose} handleSignOut={handleSignOut}/>
            <div className={!accountPopupOpened ? "account-courses" : "account-courses account-courses_popup-opened"}>
                <Header authorized={authorized} name={name} handleAccountPopupOpen={handleAccountPopupOpen}/>
                <div className="account-courses__content">
                    {
                        courses
                        ?
                        <AccountEmpty text="Здесь будут отображаться ваши Практикумы" />
                        :
                        <div className="account-courses__container">
                            {coursesObj.courses.map((course) =>
                                <CourseCard num={course.testNumber} text="Практикум по" text2="заданию" disabled={course.disabled} info={course.info} />
                            )}
                        </div>
                    }
                </div>
                <Footer />
            </div>
        </>
    );
}

export default AccountCoursesPage;