import React from 'react';
import {Footer} from '../../../modules/Footer';
import {Header} from '../../../modules/Header';
import {PageSubtitle} from '../../../ui/PageSubtitle';
import './InDevelopPage.scss';

export const InDevelopPage = ({ authorized, name }) => {
  const text = 'Данная страница находится в разработке';
  return (
    <div className="in-develop-page">
      <Header authorized={authorized} name={name}/>
        <div className="in-develop-page__content">
            <PageSubtitle text={text} className="in-develop-page__text" />
        </div>
      <Footer />
    </div>
  );
}
