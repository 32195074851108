export const initialContainers = [
    {
        id: "1",
        title: "",
        tasks: [],
    },
    {
        id: "2",
        title: "",
        tasks: [],
    },
    {
        id: "3",
        title: "",
        tasks: [],
    },
    {
        id: "4",
        title: "",
        tasks: [],
    },
    {
        id: "5",
        title: "",
        tasks: [],
    },
    {
        id: "6",
        title: "",
        tasks: [],
    },
    {
        id: "7",
        title: "",
        tasks: [],
    },
    {
        id: "8",
        title: "",
        tasks: [],
    },
    {
        id: "9",
        title: "",
        tasks: [],
    },
    {
        id: "10",
        title: "",
        tasks: [],
    },
    {
        id: "11",
        title: "",
        tasks: [],
    },
    {
        id: "12",
        title: "",
        tasks: [],
    },
    {
        id: "13",
        title: "",
        tasks: [],
    },
    {
        id: "14",
        title: "",
        tasks: [],
    },
    {
        id: "15",
        title: "",
        tasks: [],
    },
    {
        id: "16",
        title: "",
        tasks: [],
    },
    {
        id: "17",
        title: "",
        tasks: [],
    },
    {
        id: "18",
        title: "",
        tasks: [],
    },
    {
        id: "19",
        title: "",
        tasks: [],
    },
    {
        id: "20",
        title: "",
        tasks: [],
    },
    {
        id: "21",
        title: "",
        tasks: [],
    },
    {
        id: "22",
        title: "",
        tasks: [],
    },
    {
        id: "23",
        title: "",
        tasks: [],
    },
    {
        id: "24",
        title: "",
        tasks: [],
    },
];