import React from 'react';
import {Header} from '../../../../modules/Header';
import './Tests.scss';
import TestsContainer from "../TestsContainer/TestsContainer";

export const Tests = ({ authorized, name }) => {

  return (
    <div className="tests">
      <Header authorized={authorized} name={name} />
      <TestsContainer />
    </div>
  );
}
