import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

export const Footer = () => {
  const icons = [
    { name: 'VK', url: 'https://vk.com/polina.dai5' },
    { name: 'Telergam', url: 'https://t.me/polina_dai5' },
    { name: 'WhatsUp', url: 'https://wa.me/79776575354' },
  ];
  const docs = [
    { title: 'Договор оферты', path: '/offerta' },
    { title: 'Политика конфеденциальности', path: '/politic' },
  ];

  return (
    <section className="footer">
      <ul className="footer__icons">
        {icons.map((i, key) => (
          <a href={i.url} key={key} target="_blank" rel="noreferrer" className="footer__icon">
            <span></span>
          </a>
        ))}
      </ul>
      {docs.map((i, key) => (
        <Link to={i.path} className="footer__text" key={key}>
          {i.title}
        </Link>
      ))}
    </section>
  );
}
