// эта функция настроена на логику 2-ой игры, когда мы проверяем первые 12 контейнеров на наличие там оставшихся слов.

export const checkResults = (containers, callback, setWrongAnswersCount, setFinishedGame) => {
    let wrongAnswersCount = 0;
    // проверяем, есть ли слова, которые еще не распределены
    const incompleteContainers = containers.filter(
        (container) => container.id < 13 && container.tasks.length !== 0
    );
    // если все слова распределены, то начинаем проверку
    if (incompleteContainers.length === 0) {
        const updatedContainers = containers.map((container) => {
            if (container.tasks.length !== 0 && container.tasks[0].answer === container.title) {
                return { ...container, answer: 'correct' };
            } else if (container.tasks.length !== 0 && container.tasks[0].answer !== container.title) {
                return { ...container, answer: 'wrong' };
            } else {
                return container;
            }
        });

        updatedContainers.forEach((container) => {
            if(container.answer === 'wrong') {
                wrongAnswersCount ++;
            }
        })
        setWrongAnswersCount(wrongAnswersCount);
        callback(updatedContainers);
        setFinishedGame(true);
    }
};