export const checkResults = (containers, callback, setWrongAnswersCount, setFinishedGame, groupTitles) => {
    let wrongAnswersCount = 0;

    // Проверяем, есть ли слова, которые еще не распределены
    const incompleteContainers = containers.filter(
        (container) => container.id > 12 && container.tasks.length !== 0
    );

    // Если все слова распределены, то начинаем проверку
    if (incompleteContainers.length === 0) {
        const updatedContainers = containers.map((container) => {
            if (container.tasks.length !== 0) {
                const draggedWord = container.tasks[0];
                const containerTitle = groupTitles[Number(container.id) >= 7 ? 1 : 0];

                if (draggedWord.group_letter === containerTitle) {
                    return { ...container, answer: 'correct' };
                } else {
                    return { ...container, answer: 'wrong' };
                }
            } else {
                return container;
            }
        });

        updatedContainers.forEach((container) => {
            if (container.answer === 'wrong') {
                wrongAnswersCount++;
            }
        });

        console.log(updatedContainers);
        setWrongAnswersCount(wrongAnswersCount);
        callback(updatedContainers);
        setFinishedGame(true);
    }
};
