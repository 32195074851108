import { createPortal } from 'react-dom';
import './Portal.scss';
import { PortalCloseButton } from './components/PortalCloseButton/PortalCloseButton';
import { getClassNames } from '../../../../helpers/getClassNames';
import { useEffect } from 'react';

export function Portal({ children, isOpen, onClose, withButton, className, buttonProps }) {
  const classNames = getClassNames(['portal', className])

  const handleBodyClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute('data-no-scroll', true);
    } else {
      document.body.removeAttribute('data-no-scroll');
    }
  }, [isOpen])

  if (!isOpen) return null;

  return createPortal(
    <div className={classNames} onClick={onClose}>
      <div className='portal__wrapper'>
        <div className='portal__body' onClick={handleBodyClick}>
          {children}
        </div>
      </div>
      {withButton && 
        <PortalCloseButton
          onClick={onClose}
          className={buttonProps?.className}
        />
      }
    </div>,
    document.body
  );
}