import React from 'react';
import {Header} from "../../../../modules/Header";
import {Footer} from "../../../../modules/Footer";
import TestResultsContainer from "../TestResultsContainer/TestResultsContainer";

export const TestResults = ({authorized, name}) => {

    return (
        <div className="test-results">
            <Header authorized={authorized} name={name} />
            <TestResultsContainer authorized={authorized} />
            <Footer />
        </div>
    );
};
