import React from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import './DragCard.scss';
import {useSelector} from "react-redux";

export const DragCard = ({ container, isHovered, game }) => {
    const checkAnswers = useSelector((state) => state.game.checkAnswers);

    let classes = "word-container";
    let containerClass = "container";

    switch (game) {
        case "one":
            classes += ' word-container-one';
            containerClass += " container-one";
            break;
        case "two":
            classes += ' word-container-two';
            break;
        default:
            classes = ' word-container';
    }

    if(container.tasks.length !== 0 && game === 'one') {
        classes += " word-container-one-filled";
    }

    if (container.answer === 'correct') {
        classes += ' correct';
    } else if (container.answer === 'wrong') {
        classes += ' wrong';
    }

    return (
        <div className={containerClass} key={container.id}>
            {container.title && game === "two" && <h2 className="word-container-title">{container.title}</h2>}
            <Droppable droppableId={container.id} isDropDisabled={container.tasks.length >= 1}>
                {(provided) => (
                    <div className={classes} ref={provided.innerRef} {...provided.droppableProps}>
                        {container.tasks.map((task, index) => (
                            <Draggable
                                key={task.id}
                                draggableId={`item-${task.id.toString()}`}
                                index={index}
                                isDragDisabled={checkAnswers}
                            >
                                {(provided) => (
                                    <div
                                        className={`word-card word-card-${game} ${
                                            isHovered === `item-${task.id.toString()}` ? `hovered-${game}` : ''}`}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                    >
                                        <div>{task['task_word']}</div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};
