import GameButton from '../../GameButton/GameButton';
import './GameModal.scss';

export function GameModal({ onClick, incompleteTasks }) {
  return (
    <div className="fourth-game-modal">
      <h3 className="fourth-game-modal__title">
        Здесь были допущены ошибки, давай их исправим:
      </h3>
      <ul className="fourth-game-modal__list">
        {incompleteTasks.map((task) => (
          <li>{task.question}</li>
        ))}
      </ul>
      <GameButton
        onClick={onClick}
        className="fourth-game-modal__btn"
        size="sm"
      >
        Приступить
      </GameButton>
    </div>
  );
}