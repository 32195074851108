import React, { useState } from "react";
import "./Pause.scss";

const Pause = ({ isOpen, onClose }) => {
    const [ isPopupShowing, setIsPopupShowing ] = useState(isOpen);

    const handleClose = () => {
        setIsPopupShowing(false);
        onClose();
    }

    return (
        <div className={isPopupShowing ? "popup popup_opened" : "popup"}>
            <div className="popup__container">
                <p className="popup__text">Игра приостановлена</p>
                <button
                    onClick={handleClose}
                    className="popup__close"
                    type="button">
                    продолжить
                </button>
            </div>
            <button onClick={handleClose} className="popup__overlay" />
        </div>
    );
}

export default Pause;