import React from "react";
import { useSelector } from "react-redux";
import "./Counter.scss";

export const Counter = ({ game, steps }) => {
	const count = useSelector((state) => state.game.countSteps);
	//const steps = useSelector((state) => state.game.steps);
	let classes = "progress-bar__count";

  switch (game) {
        case "one":
            classes = 'progress-bar__count';
            break;
        case "two":
            classes += ' progress-bar__count-two';
            break;
        case "three":
            classes += ' progress-bar__count-three';
            break;
        case "four":
            classes += ' progress-bar__count-four';
            break;
        default:
            classes = 'progress-bar__count';
    }

  return (
      <p className={classes}>{count} из {steps}</p>
  );
};
