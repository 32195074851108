import './PauseButton.scss';
import pause from "../../images/pause.svg";

export const PauseButton = ({pauseBtnCallback}) => {
    
    return (
        <>
        {pauseBtnCallback
            ? <button className='pause-btn' type="button" onClick={pauseBtnCallback}>
                <img src={pause} alt="back" className="pause-btn__image" />
              </button>
            : null
        } 
        </>
    );
};