import { getClassNames } from '../../../../../../helpers/getClassNames';
import { ReactComponent as CloseIcon } from './images/close-icon.svg';

import './PortalCloseButton.scss';

export function PortalCloseButton({ onClick, className }) {
  const classNames = getClassNames(['portal__close-btn', className])

  return (
    <button type="button" onClick={onClick} className={classNames}>
      <CloseIcon />
    </button>
  );
}