import React, {useEffect} from 'react';
import {SingleAnswer} from '../../../../components/SingleAnswer';
import {MultipleAnswer} from '../../../../components/MultipleAnswer';
import {InsertInput} from '../../../../components/InsertInput';
import {CorrespondTest} from '../../../../components/CorrespondTest';
import './Quiz.scss';
import TestBackButton from "../TestBackButton/TestBackButton";
import TestButton from "../TestButton/TestButton";
import {useRandomImage} from "../../../../hooks/useRandomImage";
import {useLocation, useParams} from "react-router-dom";
import {FormattedParagraph} from "../../../../ui/FormattedParagraph";

const Quiz = (props) => {
    const [img, buttonClass, getRandomBackground, getRandomPanda] = useRandomImage(props.question.id);
    const {pathname} = useLocation();
    const {numberTest} = useParams();

    useEffect(() => {
        if(pathname === `/tests/${numberTest}`) {
            let index = props.quizQuestions.questions.indexOf(props.question);
            getRandomBackground();
            getRandomPanda(index);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.question]);

    if(props.question.question_type !== 'correspond') {
        return (
            <div className={`quiz ${img}`}>
                <h3 className="quiz__title">{props.question.question_title}</h3>
                {props.question.question_description &&
                    props.question.question_description.split('&').map((desc) =>
                        <FormattedParagraph text={desc} position="description"/>
                    )
                }
                {props.question.question_type === 'single' && (
                    <SingleAnswer
                        selectedOption={props.selectedOption}
                        question={props.question}
                        handleSingleAnswerClick={props.handleSingleAnswerClick}
                    />
                )}
                {props.question.question_type === 'multiple' && (
                    <MultipleAnswer
                        handleMultipleAnswerClick={props.handleMultipleAnswerClick}
                        isChecked={props.isChecked}
                        question={props.question}
                    />
                )}
                {props.question.question_type === 'insertion' && (
                    <InsertInput
                        question={props.question}
                        handleInsertInput={props.handleInsertInput}
                        input={props.input}
                    />
                )}
                <div className="quiz__buttons-area">
                    {props.step !== 0 &&
                        <TestBackButton handleButtonBackClick={props.handleButtonBackClick} />
                    }
                    <TestButton
                        step={props.step}
                        handleFinalButtonClick={props.handleFinalButtonClick}
                        question={props.question}
                        quizQuestions={props.quizQuestions}
                        buttonClass={buttonClass}
                        handleButtonClick={props.handleButtonClick}
                        input={props.selectedOption || props.isChecked.some(item => item !== false) || props.input}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <CorrespondTest
                    correspondInput1={props.correspondInput1}
                    correspondInput2={props.correspondInput2}
                    correspondInput3={props.correspondInput3}
                    correspondInput4={props.correspondInput4}
                    correspondInput5={props.correspondInput5}
                    setCorrespondInput1={props.setCorrespondInput1}
                    setCorrespondInput2={props.setCorrespondInput2}
                    setCorrespondInput3={props.setCorrespondInput3}
                    setCorrespondInput4={props.setCorrespondInput4}
                    setCorrespondInput5={props.setCorrespondInput5}
                    question={props.question}
                    step={props.step}
                    setAnswer={props.setAnswer}
                    handleButtonClick={props.handleButtonClick}
                    quizQuestions={props.quizQuestions}
                    handleFinalButtonClick={props.handleFinalButtonClick}
                    handleButtonBackClick={props.handleButtonBackClick}
                    sendTestAnswers={props.sendTestAnswers}
                />
            </div>
        )
    }
};

export default Quiz;
