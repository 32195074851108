import React from 'react';
import './Progress.scss';
import ProgressButton from '../ProgressButton/ProgressButton';

function Progress(props) {
  return (
    <ul className="progress">
      {(props.array && props.quizQuestions.questions.length > 20) ||
      props.quizQuestions.questions.length <= 20
        ? props.quizQuestions.questions.slice(0, 20).map((question, index) => (
            <li key={question.id}>
              <ProgressButton
                index={index}
                setStep={props.setStep}
                question={question}
                retrieveAnswer={props.retrieveAnswer}
                resetAnswer={props.resetAnswer}
                result={props.result}
                answers={props.answers}
                quizQuestions={props.quizQuestions}
                array={props.array}
              />
            </li>
          ))
        : props.quizQuestions.questions.slice(0, 1).map((question, index) => (
            <li key={question.id}>
              <ProgressButton
                index={index}
                setStep={props.setStep}
                question={question}
                retrieveAnswer={props.retrieveAnswer}
                resetAnswer={props.resetAnswer}
                result={props.result}
                answers={props.answers}
                quizQuestions={props.quizQuestions}
                array={props.array}
              />
            </li>
          ))}
      {props.quizQuestions.questions.length > 20 && (
        <button className="progress__button-dots" onClick={() => props.handleButton()}>
          ...
        </button>
      )}
      {props.array && props.quizQuestions.questions.length > 20
        ? props.quizQuestions.questions.slice(-1).map((question, index) => (
            <li key={question.id}>
              <ProgressButton
                index={index}
                setStep={props.setStep}
                question={question}
                retrieveAnswer={props.retrieveAnswer}
                resetAnswer={props.resetAnswer}
                result={props.result}
                answers={props.answers}
                quizQuestions={props.quizQuestions}
                array={props.array}
              />
            </li>
          ))
        : props.quizQuestions.questions.slice(20).map((question, index) => (
            <li key={question.id}>
              <ProgressButton
                index={index}
                setStep={props.setStep}
                question={question}
                retrieveAnswer={props.retrieveAnswer}
                resetAnswer={props.resetAnswer}
                result={props.result}
                answers={props.answers}
                quizQuestions={props.quizQuestions}
                array={props.array}
              />
            </li>
          ))}
    </ul>
  );
}

export default Progress;
