import {useEffect, useState} from "react";
import "./Answer.scss";

const Answer = ({correct, incorrect, isAnswerCorrect, currentIndex}) => {
    const [ isAnswerClicked, setIsAnswerClicked ] = useState(false);
    const [ chosen, setChosen ] = useState();

    useEffect(() => {
        setIsAnswerClicked(false);
        isAnswerCorrect(false);
        // eslint-disable-next-line
    }, [currentIndex])

    const variants = [
        {value: correct.toUpperCase(), isCorrect: true},
        {value: incorrect.toUpperCase(), isCorrect: false}
    ].sort((a,b) => a.value.localeCompare(b.value));

    const checkAnswer = (i, variant) => {
        setIsAnswerClicked(true);
        if (variant.isCorrect) {
            isAnswerCorrect(true);
        }
        setChosen(i);
    }

    return (
        <div className="answer-btn">
            {variants.map((variant, i) => (
                <button key={i} disabled={isAnswerClicked} className={`answer-btn__variant ${(i === chosen) ? "chosen" : ''}`} onClick={() => checkAnswer(i, variant)}>
                    {variant.value}
                </button>
                ))}
        </div> 
    )
}

export default Answer;