import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import './BuyPracticumPopup.scss';
import Logo from '../../../../modules/Header/images/лого.svg';
import CalendarIcon from '../../images/icon-calendar.svg';
import ChatIcon from '../../images/icon-chat.svg';
import MoneyIcon from '../../images/icon-money.svg';
import {ClosePopupButton} from '../../../../ui/ClosePopupButton';
import PracticumCover from '../PracticumCover/PracticumCover';
import PracticumDescription from '../PracticumDescription/PracticumDescription';
import {handleBuyButton} from "../../helpers/createPaymentPath";
import {scrollToSection} from "../../../../helpers/scrollToSection";

export const BuyPracticumPopup = () => {
  const [path, setPath] = React.useState('');
  const navigate = useNavigate();
  const {numberTest} = useParams();

  React.useEffect(() => {
    handleBuyButton(Number(numberTest), setPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClosePopup = () => {
    navigate(`/test-results/${numberTest}`);
  }

  return (
    <div className="buy-practicum">
      <header className="buy-practicum__header">
        <img src={Logo} className="logo buy-practicum__logo" alt="Логотип" />
        <h1 className="buy-practicum__title buy-practicum__title_hidden">Практикум по {numberTest} заданию</h1>
        <div className="buy-practicum__title-520px">
          <h1 className="buy-practicum__title">Практикум</h1>
          <h1 className="buy-practicum__title buy-practicum__title_second">по {numberTest} заданию</h1>
        </div>
        <ClosePopupButton handleClosePopup={handleClosePopup} classes="" />
      </header>
      <main>
        <div className="buy-practicum__content">
          <PracticumDescription taskNumber={numberTest} handleClickScroll={() => scrollToSection('buy-button')} />
          <div className="buy-practicum__right-side-content">
            <div className="buy-practicum-cover">
              <PracticumCover taskNumber={numberTest} />
            </div>
            <div className="buy-practicum__info">
              <h2 className="buy-practicum__info-title">Внимание!</h2>
              <ul className="buy-practicum__info-list">
                <li className="buy-practicum__info-item">
                  <img
                    src={CalendarIcon}
                    className="buy-practicum__info-icon"
                    alt="Иконка календаря"
                  />
                  <p className="buy-practicum__info-text">
                    Доступ к практикуму будет открыт 10 мая.
                  </p>
                </li>
                <li className="buy-practicum__info-item">
                  <img src={ChatIcon} className="buy-practicum__info-icon" alt="Иконка чата" />
                  <p className="buy-practicum__info-text">
                    Если ты оплатишь практикум до 8 мая, мы тебя пригласим на занятие с
                    преподавателем, где разберешь интересующую тебя тему в рамках ЕГЭ
                  </p>
                </li>
                <li className="buy-practicum__info-item">
                  <img src={MoneyIcon} className="buy-practicum__info-icon" alt="Иконка денег" />
                  <p className="buy-practicum__info-text">
                    Стоимость курса:
                    <span className="buy-practicum__info-text_highlighted">200 рублей</span>
                  </p>
                </li>
              </ul>
            </div>
            <p className="buy-practicum__support">
                Если остались вопросы, свяжитесь с нами в Telegram: @nastia_sergeeva
            </p>
          </div>
        </div>
        <a id="buy-button" className="buy-practicum__link" href={path && path} target="_blank" rel="noreferrer">
            Купить практикум по {numberTest} заданию
        </a>
      </main>
    </div>
  );
};
