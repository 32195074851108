import React from 'react';
import './SaveResultPopup.scss';

function SaveResultPopup({classes, setSavePopup}) {

    const onClose = () => {
        setSavePopup(false);
    }

    return (
            <div className={classes}>
                <div className="save-popup__container">
                    <button type="button" className="registration__close-button" onClick={() => onClose()}></button>
                    <h5 className="save-popup__text">Тест сохранен в личном кабинете</h5>
                    <span className="save-popup__rocket"></span>
                    <span className="save-popup__panda"></span>
                </div>
            </div>
    );
}

export default SaveResultPopup;