import React from 'react';
import ResultTaskButton from '../ResultTaskButton/ResultTaskButton';
import './ResultProgressBar.scss';

function ResultProgressBar({ checkedAnswers, quizQuestions, setStep }) {
  let classes = 'result-progress-bar';

  if (quizQuestions.questions && quizQuestions.length > 20) {
    classes += ' result-progress-bar_small-width';
  }

  return (
    <ul className={classes}>
      {quizQuestions.questions &&
        quizQuestions.questions.map((question, index) => (
          <li key={question.id}>
            <ResultTaskButton
              questionNumber={index + 1}
              question={question}
              checkedAnswers={checkedAnswers}
              setStep={setStep}
            />
          </li>
        ))}
    </ul>
  );
}

export default ResultProgressBar;
