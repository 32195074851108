// import { BackButton } from '../../../components/BackButton/BackButton';
import { ProgressBar } from '../progress-bar';
import { GameTask } from '../GameTask/GameTask';
import Panda from '../../images/gifs/sleep-panda.gif';
import BtnFlowers from '../../images/icons/flowers.svg';
import './FourthGame.scss';
import { useDispatch } from 'react-redux';
import { nextStep, setCount } from '../../../store/gameSlice';
import { useTasks } from '../../hooks/useTasks';
import { useParams } from 'react-router-dom';
import bud from '../../images/icons/bud.svg';
import flower from '../../images/icons/flower.svg';
import { Portal } from '../../../../../components/Portal';
import { useCallback, useState } from 'react';
import { shuffle } from '../../helpers/shuffle';
import { GameOverModal, GameModal } from '../modals';
import GameButton from '../GameButton/GameButton';
import { transformGameData } from '../../helpers/transformGameData';

export function FourthGame() {
  const dispatch = useDispatch();
  const { gameID } = useParams();
  const { 
          tasks,
          currentTask,
          setTasks,
          setCurrentTask,
          isLoading,
          initialGameData,
          setStepsState,
          stepsState
        } = useTasks({ gameID });

  const [isModalOpen, setModalOpen] = useState(false);
  const [isCheck, setCheck] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [gameOver, setGameOver] = useState(false);

  const handleAnswerClick = (id) => {
    const updatedTasks = tasks.map((task, idx) => {
      if (idx === currentTask) {
        task.selectedId = id;
        task.correct = task.answers.find((answer) => answer.id === id)?.correct ?? false;
      }
      return task;
    });

    setTasks(updatedTasks);
  };

  const goNextTask = () => {
    const checkedTasks = tasks.map((task, idx) => {
      if (idx === currentTask) {
        task.checked = true;
      }
      return task;
    });

    const checkedSteps = stepsState.map((step) => {
      if (step.id === tasks[currentTask].id) {
        step.checked = true;
        step.correct = tasks[currentTask].correct;
      }
      return step;
    });

    setTasks(checkedTasks);
    setStepsState(checkedSteps);
    setCheck(true);

    setTimeout(() => {
      if (tasks && tasks[currentTask].checked) {
        if (tasks.length - 1 !== currentTask) {
          setCurrentTask((prev) => prev + 1);
        } else {
          setModalOpen(true);
        }
      }

      setCheck(false);

      if (!repeat) {
        dispatch(nextStep());
      }
    }, 2000)
  };

  const repeatTasks = () => {
    setRepeat(true);
    const incorrectTasks = [];

    tasks.forEach((task) => {
      if (!task.correct) {
        incorrectTasks.push({
          ...task,
          checked: false,
          selectedId: null,
          correct: null,
        })
      }
    })

    setTasks(incorrectTasks);
    setCurrentTask(0);
    setModalOpen(false);
  };

  const checkGameEnd = () => 
    tasks && tasks.filter((task) => !task.correct).length === 0 && tasks[currentTask].correct;

  const getIncompleteTasks = useCallback(() => {
    return tasks.filter((task) => !task.correct).map((task) => {
      return {
        question: task.question,
      }
    })
  }, [tasks]);

  const restartGame = () => {
    const transformed = shuffle(transformGameData(initialGameData));

    setTasks([...transformed]);
    setStepsState([...transformed]);
    setRepeat(false);
    setCurrentTask(0);
    setModalOpen(false);
    dispatch(setCount(0));
    setGameOver(false);
  };

  const closeModal = () => {
    if (!checkGameEnd()) return;
    setModalOpen(false);
    setGameOver(true);
  };

  return (
    <div className='fourth-game'>
      {tasks && !isLoading &&
        <>
          <ProgressBar className="fourth-game__progress-bar" steps={initialGameData.length}>
            <ProgressBar.Counter className="fourth-game__counter" />
            <ProgressBar.StepsList
              initialIcon={bud}
              finalIcon={flower}
              renderSteps={(idx) => (
                  <ProgressBar.Step
                    key={idx}
                    className={`fourth-game__step ${stepsState[idx] 
                      && stepsState[idx].checked ? (stepsState[idx].correct ? 'correct' : 'incorrect') : ''}`}
                  />
                )}
              />
          </ProgressBar>
          <h2 className='fourth-game__title'>Выберите правильный ответ</h2>
          <div className='fourth-game__content'>
            <GameTask task={tasks[currentTask]} onAnswerClick={handleAnswerClick} />
          </div>
          <GameButton
            onClick={gameOver ? restartGame : goNextTask}
            disabled={tasks[currentTask].selectedId === null || isCheck}
            imgProps={{ 
              src: BtnFlowers,
              className: 'fourth-game__game-btn-icon'
            }}
            className="fourth-game__game-btn"
          >
            {gameOver ? 'Повторить' : 'Вперед'}
          </GameButton>
          <img src={Panda}
            className='fourth-game__decoration fourth-game__decoration_panda'
            alt='sleeping panda'
          />
          <Portal
            isOpen={isModalOpen}
            withButton={checkGameEnd()}
            buttonProps={{ className: 'fourth-game__modal-close-btn' }}
            onClose={closeModal}>
              {checkGameEnd()
                ? <GameOverModal tasksAmount={initialGameData.length} />
                : <GameModal onClick={repeatTasks} incompleteTasks={getIncompleteTasks()} />
              }
          </Portal>
        </>
      }
      {/* <BackButton className='fourth-game__close-btn' /> */}
    </div>
  );
}