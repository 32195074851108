//рендер данных (12 карточек). Названия ячеек по порядку, а сами карточки рандомно

export const renderInitialData = (words, containers, callback) => {
    if (words.length === 12) {
        const renderedContainers = [...containers];
        const randomIndices = [];

        for (let i = 0; i < 12; i++) {
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * words.length);
            } while (randomIndices.includes(randomIndex));

            randomIndices.push(randomIndex);
            renderedContainers[i + 12].tasks.push(words[randomIndex]);
        }

        callback(renderedContainers);
    }
}