import { createSlice } from "@reduxjs/toolkit";

const toolkitSlice = createSlice({
	name: "gameSlice",
	initialState: {
		countSteps: 0,
		steps: 0,
		checkAnswers: false,
	},
	reducers: {
		nextStep(state) {
			state.countSteps = state.countSteps + 1;
		},
		prevStep(state) {
			state.countSteps = state.countSteps - 1;
		},
		resetCount(state) {
			state.countSteps = 0;
		},
		setCheckAnswers(state, action) {
			state.checkAnswers = action.payload;
		},
		setCount(state, action) {
			state.countSteps = action.payload;
		},
		setSteps(state, action) {
			state.steps = action.payload;
		},
	},
});
export default toolkitSlice.reducer;
export const {
	nextStep,
	prevStep,
	resetCount,
	setCheckAnswers,
	setSteps,
	setCount,
} = toolkitSlice.actions;
