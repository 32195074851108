import React, {useEffect} from 'react';
import './ProgressButton.scss';

function ProgressButton(props) {

    let questionNumber = props.index + 1;

    // добавление решенных заданиям зеленого фона на бегунке
    let classes = [];
    if(questionNumber % 2 === 0) {
        classes.push('progress-button progress-button_solved-even');
    } else {
        classes.push('progress-button progress-button_solved-odd');
    }


    useEffect(() => {
        initialCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.array]);

    /* функция проверки наличия ответов, если они есть, то при загрузке страницы нас перекидывает на первый НЕотвеченный вопрос */
    const initialCheck = () => {
        if(!props.answers[props.question.id]) {
            props.setStep(Object.entries(props.answers).length);
        } else {
            props.setStep(0);
        }
    }

    // функция проверки введенных ответов при переключении кнопок бегунка
    const checkAnswer = () => {
        props.setStep(props.index);
        if(!props.answers[props.question.id]) {
            props.resetAnswer(props.question);
        } else {
            props.retrieveAnswer(props.question);
        }
    }

    return (
        <button onClick={() => checkAnswer()} disabled={!props.answers[props.question.id]} className={props.result[props.question.id] || props.answers[props.question.id] ? classes.join(' ') : "progress-button"}>{props.index + 1}</button>
    );
}

export default ProgressButton;