import React, {useEffect, useState} from 'react';
import Tilt from "react-parallax-tilt";
import {Link} from "react-router-dom";
import EgeButtonImg from "../EgeButtonImg/EgeButtonImg";
import {breackPointEge} from "../../../../constants/ConstantsValidation";
import useWindowDimensions from "../../../../helpers/screenSizeDetector";
import * as EgeApi from "../../api/EgeApi";
import {Loader} from "../../../../ui/Loader";
import './EgeTasksList.scss';

export const EgeTasksList = ({authorized}) => {
    const green = '#D4E779';
    const yellow = '#FFEE5C';
    const windowWidth = useWindowDimensions().width;
    const [initialTests, setInitialTests] = useState([]);

    const testsFinished = JSON.parse(localStorage.getItem('tests-completed')) || [];
    //const [isTestsStarted, setIsTestsStarted] = useState(new Array(28).fill(false));
    const isTestsStarted = new Array(28).fill(false);
    const [isTestsComplete, setIsTestsComplete] = useState(new Array(28).fill(false));

    useEffect(() => {
        EgeApi.getInitialTests()
            .then((data) => {
                setInitialTests(data);
                if (authorized) {
                    getFinishedTests();
                }
            })
            .catch((err) => {
                console.log(err);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Функция закрашивания зеленым завершенные тесты
    const getFinishedTests = () => {
        if (Object.keys(testsFinished.length !== 0)) {
            setIsTestsComplete(testsFinished);
        }
    };

    if (initialTests.length === 0) {
        return (
            <Loader/>
        )
    } else {
        return (
            <div className="ege__courses">
                {initialTests.map((i, index) =>
                    i.theme_title === '1' ? (
                        <div
                            className="ege__course-container"
                            key={i.id}>
                            <Tilt>
                                <Link className="ege__course" to={`/welcome-tests/${index + 1}`} id={i.id}>
                                    <EgeButtonImg
                                        number={i.theme_title}
                                        fill={isTestsComplete[index] ? green : 'white'}
                                        stroke={`${
                                            isTestsStarted[index] ? (isTestsComplete[index] ? green : yellow) : green
                                        } `}
                                    />
                                    <p className="ege__course-text">{i.theme_title}</p>
                                </Link>
                            </Tilt>
                            <span
                                className={`ege__course-books ${
                                    windowWidth < breackPointEge ? 'ege_hiden' : ''
                                }`}></span>
                        </div>
                    ) : (
                        <div
                            key={i.id}
                            className="ege__course-container">
                            <Tilt>
                                <Link
                                    className={`ege__course ${
                                        index + 1 === 27 || index + 1 === 28 ? 'ege__course_big' : ''
                                    }`}
                                    to={`/welcome-tests/${index + 1}`}>
                                    <EgeButtonImg
                                        number={i.theme_title}
                                        fill={isTestsComplete[index] ? green : 'white'}
                                        stroke={`${
                                            isTestsStarted[index]
                                                ? isTestsComplete[index]
                                                    ? '#D4E779'
                                                    : '#FFE500'
                                                : '#D4E779'
                                        } `}
                                    />
                                    <p className="ege__course-text">{i.theme_title}</p>
                                </Link>
                            </Tilt>
                        </div>
                    ),
                )}
            </div>
        );
    }
}