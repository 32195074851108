import {baseUrl} from "../constants/baseUrl";

class Api {
  constructor({ baseUrl }) {
    this._baseUrl = baseUrl;
    this._headers = {
      'Content-Type': 'application/json',
    };
  }
  async _handleResponse(res) {
    const result = await res.json();
    return res.ok ? result : Promise.reject(result);
  }

  getInitialQuestions(number) {
    return fetch(`${this._baseUrl}/v1/grade/ege/${number}/`, {
      method: 'GET',
      headers: this._headers,
    }).then(this._handleResponse);
  }

  /*
  getPassedTests() {
    return fetch(`${this._baseUrl}/v1/passed_theme/`, {
      method: 'GET',
      headers: {
        ...this._headers,
        authorization: `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
      },
    }).then(this._handleResponse);
  }
   */

  loginUser(email, password) {
    return fetch(`${this._baseUrl}/auth/jwt/create/`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        email,
        password,
      }),
    }).then(this._handleResponse);
  }
  checkToken(token) {
    return fetch(`${this._baseUrl}/auth/jwt/verify`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        token,
      }),
    }).then(this._handleResponse);
  }

  registerUser({ firstName, lastName, phone, email, password }) {
    return fetch(`${this._baseUrl}/auth/users/`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        username: `${firstName}+${email}`,
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email,
        password,
      }),
    }).then(this._handleResponse);
  }

  getUser() {
    return fetch(`${this._baseUrl}/auth/users/me`, {
      method: 'GET',
      headers: {
        ...this._headers,
        authorization: `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
      },
    }).then(this._handleResponse);
  }

  /*
  sendUserInfo({ password }) {
    return fetch(`${this._baseUrl}/auth/users/me`, {
      method: 'PATCH',
      headers: {
        ...this._headers,
        authorization: `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        username: password,
      }),
    }).then(this._handleResponse);
  }

   */
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Api({
  baseUrl: baseUrl,
});
