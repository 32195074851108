import React from 'react';
import './TeachersList.scss';
import PhotoAnna from "../../images/teachers-anna.jpg";
import PhotoPolina from "../../images/teachers-polina.jpg";
import TeachersListItem from "../TeachersListItem/TeachersListItem";

const TeachersList = () => {
    const teachers = [
        {
            img: PhotoAnna,
            name: 'Анна Пименова',
            info: [
                'Ведущий эксперт ЕГЭ по русскому языку и литературе',
                'Преподаватель вуза',
                'Опыт работы с учениками - больше 10 лет.'
            ],
            text: [
                '“Я знаю, что будет, если начать в сочинении абзац' +
                'со слова «также» или назвать Катерину из пьесы Островского «Гроза» девушкой.',
                'Знание формата экзамена и понимание, что ждёт от тебя ' +
                'поверяющий, играют важную роль в успешной сдаче ЕГЭ. ' +
                'Мои ученики знают, как не потерять баллы на экзамене”.'

            ]
        },
        {
            img: PhotoPolina,
            name: 'Полина Кузнецова',
            info: [
                'Основатель образовательной платформы "Дай пять!"',
                'Автор курса "Победи ЕГЭ"',
                'Филолог, журналист'
            ],
            text: [
                '”Я больше 10-ти лет работаю с учениками и я точно знаю, что последний год школы, ' +
                'подготовка к ЕГЭ, поступление в институт - это очень стрессовый период и ' +
                'для учеников, и для родителей. Ребята нервничают, не знают, ' +
                'за что хвататься. Я хочу изменить это”.'

            ]
        },
    ]

    return (
        <ul className="teachers__list">
            {
                teachers.map((teacher) => (
                    <TeachersListItem key={teacher.name} teacher={teacher} />
                ))
            }
        </ul>
    );
};

export default TeachersList;