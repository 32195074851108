import React from "react";
import { useSelector } from "react-redux";
import larva from "../../images/larva-first-game-steps.svg";
import butterfly from "../../images/butterfly-first-game-steps.svg";
import bud from '../../images/bud.svg';
import flower from '../../images/flower.svg';
import caviar from "../../images/caviar.svg";
import fish from "../../images/fish.svg";
import rocket from "../../images/rocket.svg";
import rocketFire from "../../images/rocket-fire.svg";
import './StepsList.scss';
import {Step} from "../Step/Step";

export const StepsList = ({steps, game, wrongAnswers}) => {
    const count = useSelector((state) => state.game.countSteps);
    //const steps = useSelector((state) => state.game.steps);
    const screenWidth = useSelector((state) => state.pageWidth);
    let stepsListWidth;

    if (screenWidth.pageWidth > 1200) {
        stepsListWidth = 1200 * 0.9 * 0.8;
    } else if (screenWidth.pageWidth > 450) {
        stepsListWidth = screenWidth.pageWidth * 0.8 * 0.8;
    } else {
        stepsListWidth = screenWidth.pageWidth * 0.95 * 0.8;
    }

	let initialHero;
	let finalHero;
    let finalClass;

	switch (game) {
        case "one":
            initialHero = larva;
            finalHero = butterfly;
            break;
        case "two":
            initialHero = caviar;
            finalHero = fish;
            break;
        case "three":
            initialHero = rocket;
            finalHero = rocketFire;
            finalClass = "hero--three"
            break;
        case "four":
            initialHero = bud;
            finalHero = flower;
            break;
        default:
            initialHero = larva;
            finalHero = butterfly;
    }

	
    return (
        <ul className="progress-bar__steps" style={{width: `${stepsListWidth}px`, maxWidth: "906px"}}>
            <img
                alt="картинка персонажа"
                src={count === steps ? finalHero : initialHero}
                className={count === steps ? `progress-bar__progress ${finalClass}` : "progress-bar__progress"}
                style={
                    count === steps
                        ? { left: `${count * (stepsListWidth / steps) - (stepsListWidth / steps * 0.8)}px`, bottom: "-10px" }
                        : { left: `${count * (stepsListWidth / steps) - (stepsListWidth / steps * 0.1)}px` }
                }
            />
            {Array(steps).fill().map((_, index) => {
                const isWrong = index >= (steps - wrongAnswers);
                return (
                    <Step key={index} width={`${stepsListWidth / steps}px`} game={game} isWrong={isWrong} index={index}/>
                );
            })}
        </ul>
    );
};