import React, {useEffect, useState} from 'react';
import {BackButton} from "../../../../ui/BackButton";
import ResultsCover from "../ResultsCover/ResultsCover";
import {scrollToSection} from "../../../../helpers/scrollToSection";
import ResultProgressBar from "../ResultProgressBar/ResultProgressBar";
import Carousel from "../Carousel/Carousel";
import ResultsButton from "../ResultsButton/ResultsButton";
import SaveResultPopup from "../SaveResultPopup/SaveResultPopup";
import {useParams} from "react-router-dom";
import Api from "../../../../utils/Api";
import {Loader} from "../../../../ui/Loader";
import './TestResultsContainer.scss';
import {sendAnswers} from "../../api/TestResultsApi";

const TestResultsContainer = ({authorized}) => {
    const {numberTest} = useParams();

    const [savePopup, setSavePopup] = useState(false);
    const [quizQuestions, setQuizQuestions] = useState({});
    const [question, setQuestion] = useState({});
    const [answer, setAnswer] = useState({});
    const [step, setStep] = useState(0);
    const [checkedAnswers, setCheckedAnswers] = useState({});
    const [results, setResults] = useState([]);
    const answers = JSON.parse(localStorage.getItem(`answersProfile_${numberTest}`)) || {};
    const testResults = JSON.parse(localStorage.getItem('results')) || [];

    useEffect(() => {
        if(testResults.length !== 0) {
            setResults(testResults);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Promise.all([Api.getInitialQuestions(numberTest), sendAnswers(answers, numberTest, authorized)])
            .then(([questions, answers]) => {
                console.log(answers);
                setQuizQuestions(questions);
                setCheckedAnswers(answers);
            })
            .catch((err) => console.log(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(quizQuestions.questions && checkedAnswers.questions) {
            setQuestion(quizQuestions.questions[step]);
            setAnswer(checkedAnswers.questions[quizQuestions.questions[step].id]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizQuestions, step]);

    const saveResult = (localTestNumber) => {
        let arr = [];
        if(results.length !== 0) {
            arr = results.slice(0);
            arr.push(localTestNumber);
        } else {
            arr = [localTestNumber];
        }
        localStorage.setItem('results', JSON.stringify(arr));
        setSavePopup(true);
    }

    if(checkedAnswers.length !== 0) {
        return (
            <div className="test-results__content">
                <BackButton path="/ege" />
                <ResultsCover authorized={authorized} checkedAnswers={checkedAnswers} taskNumber={numberTest} />
                <h3 className="test-results__details-info">Твой тест</h3>
                <button onClick={() => scrollToSection('results-section')} className="test-results__details-button"></button>
                <div id="results-section" className="test-results__details">
                    <ResultProgressBar checkedAnswers={checkedAnswers} quizQuestions={quizQuestions} setStep={setStep}/>
                    {quizQuestions.questions && step !== quizQuestions.questions.length &&
                        <Carousel
                            step={step}
                            questions={quizQuestions.questions}
                            question={question}
                            answer={answer}
                            handleForwardButton={() => setStep(step + 1)}
                            handleBackButton={() => setStep(step - 1)}
                            checkedAnswers={checkedAnswers}
                        />
                    }
                </div>
                {authorized && !testResults.includes(numberTest) &&
                    <ResultsButton localTestNumber={numberTest} saveResult={saveResult} text="Сохранить результат" className="test-results__button test-results__button_save" />
                }
                <SaveResultPopup setSavePopup={setSavePopup} classes={savePopup ? "save-popup save-popup_opened" : "save-popup"}/>
            </div>
        );
    } else {
        return (
            <Loader />
        )
    }
};

export default TestResultsContainer;