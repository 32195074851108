import React from 'react';
import './TeachersListItem.scss';

const TeachersListItem = ({teacher, key}) => {
    return (
        <li key={key} className="teachers__list-item">
            <img className="teachers__img teachers__img-anna" src={teacher.img} alt="Фото преподавателя Анны"/>
            <div className="teachers-info__area">
                <h3 className="teachers-info__title teachers-info__title-anna">{teacher.name}</h3>
                <ul className="teachers-info__list">
                    {
                        teacher.info.map((item) => (
                            <li className="teachers-info__list-item">
                                {item}
                            </li>
                        ))
                    }
                </ul>
                {
                    teacher.text.map((item) => (
                        <p className="teachers-info__text">
                            {item}
                        </p>
                    ))
                }
            </div>
        </li>
    );
};

export default TeachersListItem;