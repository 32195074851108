import React, {useState, useEffect} from 'react';
import './PracticumDescription.scss';
import PracticumCover from "../PracticumCover/PracticumCover";

function PracticumDescription({taskNumber, handleClickScroll}) {
    const [classes, setClasses] = useState('');

    useEffect(() => {
        getBackground();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskNumber]);

    const getBackground = () => {
        if (taskNumber >= 1 && taskNumber <= 8) {
            setClasses('buy-practicum__description buy-practicum__description_lamps');
        } else if (taskNumber >= 9 && taskNumber <= 12) {
            setClasses('buy-practicum__description buy-practicum__description_letters');
        } else if (taskNumber >= 13 && taskNumber <= 21) {
            setClasses('buy-practicum__description buy-practicum__description_punctuation');
        } else if (taskNumber >= 22 && taskNumber <= 26) {
            setClasses('buy-practicum__description buy-practicum__description_books');
        }
    }

    return (
        <div className={classes}>
            <div className="buy-practicum__description-cover-915px">
                <PracticumCover taskNumber={taskNumber} />
            </div>
            <h4 className="buy-practicum__description-title">
                Что такое Практикум по {taskNumber} заданию?
            </h4>
            <p className="buy-practicum__description-paragraph">
                Это точечная проработка задания.
            </p>
            <h4 className="buy-practicum__description-title buy-practicum__description-title-second">
                Как устроен Практикум?
            </h4>
            <ul className="buy-practicum__description-list">
                <li className="buy-practicum__description-item">
                    - Ты получаешь доступ к короткому обучающему видео
                </li>
                <li className="buy-practicum__description-item">
                    - Проходишь тест и убеждаешься, что материал усвоен
                </li>
                <li className="buy-practicum__description-item">
                    - Выполняешь практические задания до тех пор, пока прогресс не будет стабильным
                </li>
                <li className="buy-practicum__description-item">
                    - Получаешь новую порцию теории в видеоролике
                </li>
                <li className="buy-practicum__description-item">
                    - После этого проходишь текст, проверяющий твои знания теории
                </li>
                <li className="buy-practicum__description-item">
                    - И снова практическая часть, которая наглядно покажет, что материал усвоен
                </li>
            </ul>
            <button
                onClick={() => handleClickScroll()}
                className="buy-practicum__description-button-scroll">
                Перейти к покупке практикума
            </button>
            <p className="buy-practicum__description-paragraph">
                Пока не выполнено одно задание, доступ к следующему будет закрыт. <br/>
                Это сделано для того, чтобы знания остались у тебя в голове, а не в роликах.
            </p>
            <p className="buy-practicum__description-paragraph buy-practicum__description-paragraph-last">
                Задания составлены экспертом ЕГЭ и соответствуют необходимому уровню реального экзамена. <br/>
                Практикум построен таким образом, что, <span className="buy-practicum__description-paragraph_highlighted">пройдя его полностью, ты будешь уверен в</span> <span className="buy-practicum__description-paragraph_highlighted buy-practicum__description-paragraph_highlighted-second"> своих знаниях.</span>
            </p>
        </div>
    );
}

export default PracticumDescription;
