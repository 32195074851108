import { useEffect } from 'react';

export function useEscClose(isOpen, handleClose) {
  useEffect(() => {
    if (!isOpen) return;

    function handleEsc(e) {
      if (e.key === 'Escape') {
        handleClose();
      }
    }

    document.addEventListener('keydown', handleEsc);

    return () => document.removeEventListener('keydown', handleEsc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
}

export function useClickClose(
  isOpen,
  handleClose,
  openedClass,
  excludeClass1,
  excludeClass2,
  excludeClass3,
) {
  useEffect(() => {
    if (!isOpen) return;
    function handleClickClose(e) {
      if (
        !e.target.parentNode.className.includes(openedClass) &&
        !e.target.className.includes(openedClass)
      ) {
        if (
          !(
            e.target.className.includes(excludeClass1) ||
            e.target.className.includes(excludeClass2) ||
            e.target.className.includes(excludeClass3) ||
            e.target.parentNode.className.includes(excludeClass1) ||
            e.target.parentNode.parentNode.className.includes(excludeClass3)
          )
        ) {
          handleClose();
        }
      }
    }

    document.addEventListener('mousedown', handleClickClose);
    return () => {
      document.removeEventListener('mousedown', handleClickClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
}
