import React, {useEffect} from 'react';
import {useRandomImage} from "../../../hooks/useRandomImage";
import TestButton from "../../../pages/Tests/components/TestButton/TestButton";
import {useLocation, useParams} from 'react-router-dom';
import './CorrespondTest.scss';
import TestBackButton from "../../../pages/Tests/components/TestBackButton/TestBackButton";

export const CorrespondTest = (props) => {
    const location = useLocation();
    const [buttonClass, getRandomPanda] = useRandomImage(props.question.id);

    let numbersArr = [];
    const {numberTest} = useParams();
    let titleArr = props.question.question_title.split('&');

    if(location.pathname === `/test-results/${numberTest}`) {
        if(props.answer[0][0].correct_answer) {
            const arr = props.answer[0][0].correct_answer;
            numbersArr = (arr.split('("').join('')[4] + ', ' + arr.split('("').join('')[12] + ', ' + arr.split('("').join('')[21] + ', ' + arr.split('("').join('')[30] + ', ' + arr.split('("').join('')[38]).split(', ');
        }
    }

    let correspondData = {
        'А': props.correspondInput1,
        'Б': props.correspondInput2,
        'В': props.correspondInput3,
        'Г': props.correspondInput4,
    };

    useEffect(() => {
        getRandomPanda(props.question.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.step]);

    if(props.question.question_description.includes('&')) {
        correspondData['Д'] = props.correspondInput5;
    }

    const handleCorrespondInput1 = (e) => {
        const value = e.target.value;
        correspondData['A'] = value.replace(/ /g,'');
        props.setCorrespondInput1(value.replace(/ /g,''));
        props.setAnswer([correspondData]);
    }

    const handleCorrespondInput2 = (e) => {
        const value = e.target.value;
        correspondData['Б'] = value.replace(/ /g,'');
        props.setCorrespondInput2(value.replace(/ /g,''));
        props.setAnswer([correspondData]);
    }

    const handleCorrespondInput3 = (e) => {
        const value = e.target.value;
        correspondData['В'] = value.replace(/ /g,'');
        props.setCorrespondInput3(value.replace(/ /g,''));
        props.setAnswer([correspondData]);
    }

    const handleCorrespondInput4 = (e) => {
        const value = e.target.value;
        correspondData['Г'] = value.replace(/ /g,'');
        props.setCorrespondInput4(value.replace(/ /g,''));
        props.setAnswer([correspondData]);
    }

    const handleCorrespondInput5 = (e) => {
        const value = e.target.value;
        correspondData['Д'] = value.replace(/ /g,'');
        props.setCorrespondInput5(value.replace(/ /g,''));
        props.setAnswer([correspondData]);
    }

    let objLetters = {};
    let objNumbers = {};

    props.question.question_description.split('&').forEach((letter) => {
        objLetters[letter.split(': ')[0]] = letter.split(': ')[1];
    });

    props.question.answers[0].answer_title.split('&').forEach((number) => {
        objNumbers[number.split(': ')[0]] = number.split(': ')[1];
    });

    return (
        <div className="correspond">
            {location.pathname === `/tests/${numberTest}` && !props.question.question_description.includes('&') &&
                <>
                    <h3 className="correspond__title">{titleArr[0]}</h3>
                    <h4 className="correspond__subtitle">{titleArr[1]}</h4>
                </>
            }
            {location.pathname === `/tests/${numberTest}` && props.question.question_description.includes('&') &&
                <h3 className="correspond__title">{props.question.question_title}</h3>
            }
            {location.pathname === `/test-results/${numberTest}` && !props.question.question_description.includes('&') &&
                <>
                    <h3 className="correspond__question-number">Вопрос {props.step + 1}</h3>
                    <h3 className="correspond__title">{titleArr[0]}</h3>
                    <h4 className="correspond__subtitle">{titleArr[1]}</h4>
                </>
            }
            {location.pathname === `/test-results/${numberTest}` && props.question.question_description.includes('&') &&
                <>
                    <h3 className="correspond__question-number">Вопрос {props.step + 1}</h3>
                    <h3 className="correspond__title">{props.question.question_title}</h3>
                </>
            }
            <ul className="correspond__list">
                <li className="correspond__list-item">
                    { props.question.question_description && !props.question.question_description.includes('&')
                        ?
                        <p className="correspond__list-item-text">
                            {props.question.question_description.split(' ').map((word, index) =>
                                word.includes('$') || word.includes('#')
                                ?
                                (word.includes('$') && <span key={index} className="italic">{word.slice(1) + ' '}</span>)
                                || (word.includes('#') && <span key={index} className="bold">{word.slice(1) + ' '}</span>)
                                :
                                word + ' '
                            )}
                        </p>
                        :
                        <ul className="correspond__list-item-inner">
                            {Object.keys(objLetters).map((letter) =>
                                <li key={letter} className="correspond__list-item-element">
                                    <div className="correspond__list-item-counter">{letter}</div>
                                    <p className="correspond__list-item-task">
                                        {objLetters[letter].split(' ').map((word, index) =>
                                            word.includes('$') || word.includes('#')
                                                ?
                                                (word.includes('$') && <span key={index} className="italic">{word.slice(1) + ' '}</span>)
                                                || (word.includes('#') && <span key={index} className="bold">{word.slice(1) + ' '}</span>)
                                                :
                                                word + ' '
                                        )}
                                    </p>
                                </li>
                            )}
                        </ul>
                    }
                </li>
                <li className="correspond__list-item">
                    <ul className="correspond__list-item-inner">
                        { Object.keys(objNumbers).map((number) =>
                            <li key={number} className="correspond__list-item-element">
                                <div className="correspond__list-item-counter">{number}</div>
                                <p className="correspond__list-item-task">
                                    {objNumbers[number]}
                                </p>
                            </li>
                        )}
                    </ul>
                </li>
            </ul>
            { location.pathname === `/test-results/${numberTest}` &&
                <div className="correspond-answers__area">
                    { props.checkedAnswers.right_question[props.question.id] !== 1 &&
                        <div className="correspond-answers__area-item">
                            <h4 className="correspond-answers__title correspond-answers__title-wrong">Твой ответ:</h4>
                            <ul className="correspond-answers__list correspond-answers__list-wrong">
                                <li className="correspond-answers__item correspond-answers__item-wrong">
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">А</p>
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">{props.answer[1] && props.answer[1][0]['А']}</p>
                                </li>
                                <li className="correspond-answers__item correspond-answers__item-wrong">
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">Б</p>
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">{props.answer[1] && props.answer[1][0]['Б']}</p>
                                </li>
                                <li className="correspond-answers__item correspond-answers__item-wrong">
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">В</p>
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">{props.answer[1] && props.answer[1][0]['В']}</p>
                                </li>
                                <li className="correspond-answers__item correspond-answers__item-wrong">
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">Г</p>
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">{props.answer[1] && props.answer[1][0]['Г']}</p>
                                </li>
                                { props.question.question_description.includes('&') &&
                                <li className="correspond-answers__item correspond-answers__item-wrong">
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">Д</p>
                                    <p className="correspond-answers__item-text correspond-answers__item-text-wrong">{props.answer[1] && props.answer[1][0]['Д']}</p>
                                </li>
                                }
                            </ul>
                        </div>
                    }
                    <div className="correspond-answers__area-item">
                        <h4 className="correspond-answers__title correspond-answers__title-correct">Верный ответ:</h4>
                        <ul className="correspond-answers__list correspond-answers__list-correct">
                            <li className="correspond-answers__item correspond-answers__item-correct">
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">А</p>
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">{numbersArr.length !== 0 && numbersArr[0]}</p>

                            </li>
                            <li className="correspond-answers__item correspond-answers__item-correct">
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">Б</p>
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">{numbersArr.length !== 0 && numbersArr[1]}</p>
                            </li>
                            <li className="correspond-answers__item correspond-answers__item-correct">
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">В</p>
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">{numbersArr.length !== 0 && numbersArr[2]}</p>
                            </li>
                            <li className="correspond-answers__item correspond-answers__item-correct">
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">Г</p>
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">{numbersArr.length !== 0 && numbersArr[3]}</p>
                            </li>
                            { props.question.question_description.includes('&') &&
                            <li className="correspond-answers__item correspond-answers__item-correct">
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">Д</p>
                                <p className="correspond-answers__item-text correspond-answers__item-text-correct">{numbersArr.length !== 0 && numbersArr[4]}</p>
                            </li>
                            }
                        </ul>
                    </div>
                </div>
            }
            { location.pathname === `/tests/${numberTest}` &&
                <div className="correspond-answers__bottom-content">
                    <div className="correspond-answers">
                        <ul className="correspond-answers__list">
                            <li className="correspond-answers__item">
                                <p className="correspond-answers__item-text">А</p>
                                <input onChange={(e) => handleCorrespondInput1(e)} className="correspond-answers__item-input" type="text" name="first" value={props.correspondInput1} maxLength="1"></input>
                            </li>
                            <li className="correspond-answers__item">
                                <p className="correspond-answers__item-text">Б</p>
                                <input onChange={(e) => handleCorrespondInput2(e)} className="correspond-answers__item-input" type="text" name="second" value={props.correspondInput2} maxLength="1"></input>
                            </li>
                            <li className="correspond-answers__item">
                                <p className="correspond-answers__item-text">В</p>
                                <input onChange={(e) => handleCorrespondInput3(e)} className="correspond-answers__item-input" type="text" name="third" value={props.correspondInput3} maxLength="1"></input>
                            </li>
                            <li className="correspond-answers__item">
                                <p className="correspond-answers__item-text">Г</p>
                                <input onChange={(e) => handleCorrespondInput4(e)} className="correspond-answers__item-input" type="text" name="third" value={props.correspondInput4} maxLength="1"></input>
                            </li>
                            {props.question.question_description.includes('&') &&
                                <li className="correspond-answers__item">
                                    <p className="correspond-answers__item-text">Д</p>
                                    <input onChange={(e) => handleCorrespondInput5(e)} className="correspond-answers__item-input" type="text" name="third" value={props.correspondInput5} maxLength="1"></input>
                                </li>
                            }
                        </ul>
                    </div>
                    <div className="correspond-answers__buttons-area">
                        {props.step !== 0 &&
                            <TestBackButton handleButtonBackClick={props.handleButtonBackClick} />
                        }
                        <TestButton
                            step={props.step}
                            handleFinalButtonClick={props.handleFinalButtonClick}
                            question={props.question}
                            quizQuestions={props.quizQuestions}
                            buttonClass={buttonClass}
                            handleButtonClick={props.handleButtonClick}
                            input={Object.values(correspondData).every(elem => elem > 0)}
                        />
                    </div>
                </div>
            }
        </div>
    );
};
