import React from 'react';
import {PageTitle} from '../../../ui/PageTitle';
import { useNavigate } from 'react-router-dom';

import './About.scss';
import useWindowDimensions from '../../../helpers/screenSizeDetector';
import { breackPoint } from '../../../constants/ConstantsValidation';
import {ClosePopupButton} from "../../../ui/ClosePopupButton";

export const About = () => {
  const [isVideoAvailable, setIsVideoAvailable] = React.useState(false);
  const navigate = useNavigate();
  const title = 'Дорогой школьник, дорогой родитель!';
  const text =
    'По многолетнему опыту работы с учениками, в том числе и опыту работы в школе, мы точно знаем, что одна из самых сложных задач, которая стоит перед учеником и заботливыми родителями, – это сориентироваться в учебном материале и выстроить стратегию подготовки, стратегию ликвидации пробелов в знаниях.';
  const windowWidth = useWindowDimensions().width;

  return (
    <div className="about">
      <PageTitle text="О нас" className="page-title about__title" />
      <div className="about__content">
        <div className="about__appeal">
          <h2 className="about__appeal-title">{title}</h2>
          <p className="about__appeal-text">{text}</p>
        </div>
        <div className="about__media">
          {isVideoAvailable ? (
            <iframe
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen="allowfullscreen"
              src="https://www.youtube.com/embed/QXhmE02OT24?&autoplay=1"
              frameborder="0"
              className="about__media-iframe"></iframe>
          ) : (
            <button
              className="about__video-button"
              onClick={() => setIsVideoAvailable(true)}></button>
          )}
          <div className="about__media-info">
            <p className="about__appeal-text about__media-text">
              Мы с командой создали платформу, которая поможет ученикам и родителям простроить
              учебный маршрут, и наполняем её материалами.
            </p>
            <p className="about__appeal-text about__media-text">
              Сейчас старшеклассники могут проверить свою готовность к ЕГЭ по русскому языку и
              точечно отработать задание.
            </p>
            <span className="about__media-mask"></span>
            <span
              className={`about__media-bamboo ${
                windowWidth <= breackPoint ? 'about__media_hiden' : ''
              }`}></span>
            <span
              className={`${
                windowWidth <= breackPoint ? 'about__media-quote' : 'about__media_hiden'
              }`}></span>
          </div>
        </div>
      </div>
      <span
        className={`about__media-bamboo ${
          windowWidth > breackPoint ? 'about__media_hiden' : ''
        }`}></span>
      <ClosePopupButton classes="close-popup__button_absolute" handleClosePopup={() => navigate(-1)}/>
      <span className="about__panda"></span>
    </div>
  );
}
