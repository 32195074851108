import React from 'react';
import {Header} from '../../../modules/Header';
import './WelcomeUserPage.scss';
import {Footer} from '../../../modules/Footer';
import { Link } from 'react-router-dom';
import {AccountPopup} from '../../../modules/AccountPopup';

export const WelcomeUserPage = ({
  authorized,
  name,
  accountPopupOpened,
  handleAccountPopupClose,
  handleAccountPopupOpen,
  handleSignOut,
  currentUser
}) => {
  return (
    <>
      <AccountPopup
        accountPopupOpened={accountPopupOpened}
        handleAccountPopupClose={handleAccountPopupClose}
        handleSignOut={handleSignOut}
        currentUser={currentUser}
      />
      <div
        className={
          !accountPopupOpened
            ? 'welcome-user-page'
            : 'welcome-user-page welcome-user-page_popup-opened'
        }>
        <Header
          authorized={authorized}
          name={name}
          handleAccountPopupOpen={handleAccountPopupOpen}
        />
        <div className="welcome-user-page__content">
          <Link className="welcome-user-page__link" to="/profile">
            <div className="welcome-user-page__info">
              <div className="welcome-user-page__info-background"></div>
              <div className="welcome-user-page__info-inner">
                <h1 className="welcome-user-page__title">
                  Добро пожаловать! Школа подготовки к ЕГЭ “Дай пять!
                </h1>
              </div>
            </div>
          </Link>
        </div>
        <Footer />
      </div>
    </>
  );
}
