import React from 'react';
import './Registration.scss';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useEnterSubmit from '../../../hooks/useEnterSubmit';
import {
  regexEmail,
  regexPassword,
  regexTel,
  regexName,
  validationEmailMessage,
  validationPasswordMessage,
  validationPhoneMessage,
  validationFirstNameMessage,
  validationLastNameMessage,
} from '../../../constants/ConstantsValidation';
import {ClosePopupButton} from "../../../ui/ClosePopupButton";

export const Registration = ({ handleRegistration }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const navigate = useNavigate();

  const [isCheckboxActive, setIsCheckboxActive] = React.useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = React.useState(false);

  function handleChechbox() {
    setIsCheckboxActive(!isCheckboxActive);
  }
  function handlePasswordVisible() {
    setIsPasswordVisible(!isPasswordVisible);
  }
  function handleRepeatPasswordVisible() {
    setIsRepeatPasswordVisible(!isRepeatPasswordVisible);
  }
  // настройка, чтобы при нажатии Enter работал сабмит формы
  useEnterSubmit(handleSubmit, handleRegistration);

  return (
    <div className="registration">
      <div className="registration__container">
        <form
          className={`registration__form ${
            errors.email && errors.password ? 'registration__form_errors' : ''
          }`}
          name="registration-form"
          onSubmit={handleSubmit(handleRegistration)}>
          <div className="registration__input-container">
            <input
              {...register('firstName', {
                required: 'Пожалуйста, введите Имя',
                minLength: {
                  value: 2,
                  message: 'Имя должно содержать минимум 2 символа',
                },
                maxLength: { value: 150, message: 'Имя должно содержать максимум 150 символов' },
                pattern: {
                  value: regexName,
                  message: validationFirstNameMessage,
                },
              })}
              type="text"
              className={`registration__input ${
                errors.firstName ? 'registration__input_error' : ''
              }`}
              placeholder="Имя"
            />
            {errors.firstName ? (
              <span className="registration__error">{errors.firstName?.message}</span>
            ) : (
              ''
            )}
          </div>
          <div className="registration__input-container">
            <input
              {...register('lastName', {
                required: 'Пожалуйста, введите фамилию',
                minLength: {
                  value: 2,
                  message: 'Фамилия должна содержать минимум 2 символа',
                },
                pattern: {
                  value: regexName,
                  message: validationLastNameMessage,
                },
              })}
              type="text"
              className={`registration__input ${
                errors.lastName ? 'registration__input_error' : ''
              }`}
              placeholder="Фамилия"
            />
            {errors.lastName ? (
              <span className="registration__error">{errors.lastName?.message}</span>
            ) : (
              ''
            )}
          </div>
          <div className="registration__input-container">
            <input
              {...register('phone', {
                required: 'Пожалуйста, введите телефонный номер',
                pattern: {
                  value: regexTel,
                  message: validationPhoneMessage,
                },
              })}
              type="tel"
              className={`registration__input ${errors.phone ? 'registration__input_error' : ''}`}
              placeholder="Телефон"
            />
            {errors.phone ? (
              <span className="registration__error">{errors.phone?.message}</span>
            ) : (
              ''
            )}
          </div>
          <div className="registration__input-container">
            <input
              {...register('email', {
                required: 'Пожалуйста, введите email',
                pattern: {
                  value: regexEmail,
                  message: validationEmailMessage,
                },
                maxLength: { value: 100, message: 'email должен содержать максимум 100 символов' },
              })}
              type="email"
              className={`registration__input ${errors.email ? 'registration__input_error' : ''}`}
              placeholder="Email"
            />
            {errors.email ? (
              <span className="registration__error">{errors.email?.message}</span>
            ) : (
              ''
            )}
          </div>
          <div className="registration__input-container">
            <input
              {...register('password', {
                required: 'Пожалуйста, введите пароль',
                minLength: {
                  value: 8,
                  message: 'Пароль должен содержать минимум 8 символов',
                },
                pattern: {
                  value: regexPassword,
                  message: validationPasswordMessage,
                },
              })}
              type={`${!isPasswordVisible ? 'password' : 'text'}`}
              className={`registration__input ${
                errors.password ? 'registration__input_error' : ''
              }`}
              placeholder="Пароль"
            />
            {errors.password ? (
              <span className="registration__error">{errors.password?.message}</span>
            ) : (
              ''
            )}
            <button
              className={`registration__eye ${isPasswordVisible ? 'registration__eye_close' : ''}`}
              type="button"
              onClick={handlePasswordVisible}></button>
          </div>
          <div className="registration__input-container">
            <input
              {...register('repeatPassword', {
                required: 'Пожалуйста, введите пароль еще раз',
                validate: (value) => value === watch('password') || 'Введённый пароль не совпадает',
              })}
              type={`${!isRepeatPasswordVisible ? 'password' : 'text'}`}
              className={`registration__input ${
                errors.repeatPassword ? 'registration__input_error' : ''
              }`}
              placeholder="Повторный пароль"
            />
            {errors.repeatPassword ? (
              <span className="registration__error">{errors.repeatPassword?.message}</span>
            ) : (
              ''
            )}
            <button
              className={`registration__eye ${
                isRepeatPasswordVisible ? 'registration__eye_close' : ''
              }`}
              type="button"
              onClick={handleRepeatPasswordVisible}></button>
          </div>
          <button className="registration__button" type="button">
            Войти через Telegram
          </button>
          <button className="registration__button" type="button">
            Войти через Вконтакте
          </button>
          <label className="registration__checkbox-info">
            <input
              type="checkbox"
              className="registration__checkbox"
              {...register('checkbox', {
                required: true,
              })}></input>
            <span className="registration__checkbox-new" onClick={handleChechbox}></span>
            <span className="registration__text" onClick={handleChechbox}>
              Согласие на обработку персональных данных
            </span>
          </label>
        </form>
        <div className="registration__save-button-container">
          <span className="registration__save-button-left-lamp"></span>
          <button
            type="submit"
            className="registration__save-button"
            onClick={handleSubmit(handleRegistration)}
            disabled={
              !watch('firstName') ||
              !watch('lastName') ||
              !watch('phone') ||
              !watch('email') ||
              !watch('password') ||
              !watch('repeatPassword') ||
              !watch('checkbox') ||
              Object.entries(errors).length !== 0
            }>
            Зарегистрироваться
          </button>
          <span className="registration__save-button-right-lamp"></span>
        </div>
      </div>
      <ClosePopupButton classes="close-popup__button_absolute" handleClosePopup={() => navigate(-1)}/>
    </div>
  );
}
