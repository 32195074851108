import React from 'react';
import './AccountEmpty.scss';

export const AccountEmpty = ({text}) => {

    return (
        <p className="account-empty__banner">
            {text}
        </p>
    );
}