import './GameOverModal.scss';
import flower from '../images/flower.svg';

export function GameOverModal({ tasksAmount }) {
  return (
    <div className="fourth-game-game-over-modal">
      <h3 className="fourth-game-game-over-modal__title">
        Игра окончена, ты молодец
      </h3>
      <p className="fourth-game-game-over-modal__subtitle">
        Правильных ответов {tasksAmount} из {tasksAmount}
      </p>
      <img src={flower} alt="" className="fourth-game-game-over-modal__decoration" />
    </div>
  );
}