import React from 'react';
import './TestBackButton.scss';

function TestBackButton({handleButtonBackClick} ) {

    return (
        <button onClick={handleButtonBackClick} className="test-button-back">
            Назад
        </button>

    );
}

export default TestBackButton;