import './FirstGame.scss';
import {ProgressBar} from "../../../components/progress-bar";
import React, {useEffect, useState} from "react";
import {checkResults} from "../../helpers/checkResults";
import ResultBtn from "../../../components/result-btn/resultBtn";
import {CorrectAnswersCount} from "../../../components/CorrectAnswersCount/CorrectAnswersCount";
import {useParams} from "react-router-dom";
import {getGameDetails} from "../../api/Api";
import {useDispatch, useSelector} from "react-redux";
import {DragDropContext} from "react-beautiful-dnd";
import {initialContainers} from "../../constants/initialContainers";
import {nextStep, prevStep, setCheckAnswers} from "../../../store/gameSlice";
import {renderInitialData} from "../../helpers/renderInitialData";
import {DragCard} from "../../../components/DragCard/DragCard";
import left from "../../images/left-title.svg";
import right from "../../images/right-title.svg";
import butterflyNearBambook1 from "../../images/butterflyNearBambook1.svg";
import butterflyNearBambook2 from "../../images/butterflyNearBambook2.svg";
import butterflyNearBambook3 from "../../images/butterflyNearBambook3.svg";

export const FirstGame = () => {
    const checkAnswers = useSelector((state) => state.game.checkAnswers);
    const count = useSelector((state) => state.game.countSteps);
    const [title, setTitle] = useState('');
    const [words, setWords] = useState([]);
    const [groupTitles, setGroupTitles] = useState([]);
    const [containers, setContainers] = useState(initialContainers);
    const steps = 12;
    const [wrongAnswersCount, setWrongAnswersCount] = useState(0);
    const dispatch = useDispatch();
    const [draggedItemId, setDraggedItemId] = useState(null);
    let { gameNumber } = useParams();

    useEffect(() => {
        if(gameNumber) {
            getGameData(gameNumber);
        }
    }, [gameNumber])

    console.log(words);

    useEffect(() => {
        renderInitialData(words, containers, setContainers);
        getGroupTitles(words);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [words]);

    async function getGameData(number) {
        try {
            const response = await getGameDetails(number);
            if(response.status === 200) {
                setTitle(response.data['exercise_text']);
                setWords(response.data['tasks']);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getGroupTitles = (arr) => {
        let titles = [];
        if(arr.length !== 0) {
            titles.push(arr[0].group_letter);

            for (let i = 1; i < arr.length; i++) {
                const currentLetter = arr[i].group_letter;

                if (currentLetter !== arr[0].group_letter) {
                    titles.push(currentLetter);
                    break;
                }
            }
        }
        setGroupTitles(titles);
    }

    const onDragStart = (start) => {
        setDraggedItemId(start.draggableId);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const sourceContainer = containers.find((container) => container.id === result.source.droppableId);
        const destinationContainer = containers.find((container) => container.id === result.destination.droppableId);

        const sourceItems = Array.from(sourceContainer.tasks);
        const destinationItems = Array.from(destinationContainer.tasks);

        const [reorderedItem] = sourceItems.splice(result.source.index, 1);
        destinationItems.splice(result.destination.index, 0, reorderedItem);

        const updatedContainers = containers.map((container) => {
            if (container.id === result.source.droppableId) {
                container.tasks = sourceItems;
            } else if (container.id === result.destination.droppableId) {
                container.tasks = destinationItems;
            }
            return container;
        });

        if(Number(destinationContainer.id) < 13 && Number(sourceContainer.id) >= 13 && destinationContainer.id !== sourceContainer.id) {
            dispatch(nextStep());
        } else if (Number(destinationContainer.id) >= 13 && Number(sourceContainer.id) < 13 && destinationContainer.id !== sourceContainer.id) {
            dispatch(prevStep());
        }

        setContainers(updatedContainers);
        setDraggedItemId(null);
    };

    const finishGame = () => {
        dispatch(setCheckAnswers(true));
    }

    const resetGame = () => {
        window.location.reload();
    }

    if(words.length === 0) {
        return <div></div>
    } else {
        return (
            <div className="first-game">
                <ProgressBar game="one" steps={steps} wrongAnswers={wrongAnswersCount}/>
                <div className="first-game-container">
                    {checkAnswers ? (
                        <CorrectAnswersCount wrongAnswers={wrongAnswersCount} game="one" steps={steps}/>
                    ) : (
                        <h2 className="first-game-title">{title}</h2>
                    )}
                    <div className="first-game-content">
                        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                            <div className="groups-area">
                                <div id="1" className="groups-area-container groups-area-container-one">
                                    <div className="groups-area-container-title-box">
                                        <img
                                            className="groups-area-container-title-background groups-area-container-title-background-one"
                                            src={left}
                                            alt="bg title"
                                        />
                                        <h2 className="groups-area-container-title">{groupTitles && groupTitles[0]}</h2>
                                    </div>
                                    <div className="groups-area-container-list">
                                        {containers.map((container) =>
                                                Number(container.id) < 7 && (
                                                    <DragCard game="one" container={container} isHovered={draggedItemId}/>
                                                )
                                        )}
                                    </div>
                                </div>
                                <div id="2" className="groups-area-container">
                                    <div className="groups-area-container-title-box">
                                        <img
                                            className="groups-area-container-title-background groups-area-container-title-background-two"
                                            src={right}
                                            alt="bg title"
                                        />
                                        <h2 className="groups-area-container-title">{groupTitles && groupTitles[1]}</h2>
                                    </div>
                                    <div className="groups-area-container-list">
                                        {containers.map((container) =>
                                                Number(container.id) >= 7 && Number(container.id) < 13 && (
                                                    <DragCard game="one" keys={container.id} container={container}
                                                              isHovered={draggedItemId}/>
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="initial-words-container">
                                <div className="initial-words-container-list">
                                    {containers.map((container) =>
                                            Number(container.id) >= 13 && (
                                                <DragCard game="one" container={container} isHovered={draggedItemId}/>
                                            )
                                    )}
                                </div>
                            </div>
                        </DragDropContext>
                    </div>
                </div>
                <ResultBtn game="one" text="Узнать результат" disabled={count !== steps}
                           handleOpen={() => checkResults(containers, setContainers, setWrongAnswersCount, finishGame, groupTitles)}
                           newGame={() => resetGame()}
                />
                <img
                    src={butterflyNearBambook1}
                    alt="butterfly"
                    className="first-game__butterfly-near-bambook1"
                />
                <img
                    src={butterflyNearBambook2}
                    alt="butterfly"
                    className="first-game__butterfly-near-bambook2"
                />
                <img
                    src={butterflyNearBambook3}
                    alt="butterfly"
                    className="first-game__butterfly-near-bambook3"
                />
            </div>
        );
    }
};