const regexEmail =
  /^(([^<>()[\]\\.,;:*'#$~`+=?&%№!\s@"]+(\.[^<>()[\]\\.,;:*'#$~`+=?&%№!\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexPassword = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/g;
const regexTel = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,15}(\s*)?$/;
const regexName = /^([a-zа-яё]+)$/i;

const validationEmailMessage = 'E-mail должен соответствовать формату name@domain.ru';
const validationPasswordMessage =
  'Пароль должен быть на латинице, содержать хотя бы одну цифру, букву в нижнем регистре и букву в верхнем регистре и не должен содержать спецсимволов';
const validationPhoneMessage = 'Пожалуйста, введите валидный телефонный номер';
const validationFirstNameMessage = 'Имя не должно содержать пробел, спецсимволы и цифры';
const validationLastNameMessage = 'Фамилия не должна содержать пробел, спецсимволы и цифры';

const breackPoint = 1024;
const breackPointHeaderOne = 1085;
const breackPointHeaderTwo = 1280;
const breackPointHeaderThree = 930;
const breackPointHeaderFour = 645;

const breackPointEge = 768;

export {
  regexEmail,
  regexPassword,
  regexTel,
  regexName,
  validationEmailMessage,
  validationPasswordMessage,
  validationPhoneMessage,
  validationFirstNameMessage,
  validationLastNameMessage,
  breackPoint,
  breackPointHeaderOne,
  breackPointHeaderTwo,
  breackPointHeaderThree,
  breackPointHeaderFour,
  breackPointEge,
};
// const regexPassword /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]/
// const regexTel = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,15}(\s*)?$/;
// const regexPassword = /^(?! )(?!.* $)(?!(?:.* ){1}).*$/;
// const regexPassword = /^([a-zа-яё0-9]+|\d+)$/i;
// const regexTel = /^(\+7)(\-)\d{3}(\-)\d{3}(\-)(\d{2}(\-)\d{2})?$/;
