import React from 'react';
import {Header} from '../../../../modules/Header';
import {PageTitle} from '../../../../ui/PageTitle';
import {PageDescription} from '../../../../ui/PageDescription';
import {ClassesList} from "../ClassesList/ClassesList";
import {Footer} from '../../../../modules/Footer';
import {text} from "../../constants/pageDescText";
import './Main.scss';

export const Main = ({ authorized, name }) => {

  return (
      <>
          <Header authorized={authorized} name={name} />
          <div className="main">
            <PageTitle text="Русский язык" className="page-title main__title" />
            <p className="main__subtitle">Дорогой старшеклассник!</p>
            <PageDescription text={text} className="main__description" />
            <ClassesList />
          </div>
          <Footer />
      </>
  );
}