import { About } from "../../pages/About";
import {EgePage} from "../../pages/Ege";
import {InDevelopPage} from "../../pages/InDevelopPage";
import {Login} from "../../pages/Login";
import { Main } from "../../pages/Main";
import {BuyPracticumPopup} from "../../pages/Practicum";
import {AccountMainPage} from "../../pages/Profile";
import {AccountCoursesPage} from "../../pages/ProfileCourses";
import {AccountTestsPage} from "../../pages/ProflieSavedTests";
import {Registration} from "../../pages/Register";
import {StartTest} from "../../pages/StartTest";
import {Teachers} from "../../pages/Teachers";
import {TestResults} from "../../pages/TestResults";
import {Tests} from "../../pages/Tests";
import {WelcomeUserPage} from "../../pages/WelcomeUser";
import {FirstGame} from "../../pages/games/FirstGame";
import { SecondGame } from "../../pages/games/second-game";
import { ThirdGame } from "../../pages/games/third-game";
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "../ProtectedRoutes";
import { useContext } from "react";
import { Context } from "../../App/App";
import { FourthGame } from '../../pages/games/fourth-game';

export function AppRouter() {
  const {
    isAuthorized,
    handleAccountPopupOpen,
    currentUser,
    accountPopupOpened,
    handleAccountPopupClose,
    onSignOut,
    onUpdateUser,
    currentLocation,
    isLoading,
    setIsLoading,
    handleLogin,
    handleRegistration,
    setCurrentLocation
  } = useContext(Context);

  const paths = [
    { 
      id: 1,
      element: <ProtectedRoutes authorized={isAuthorized} /> 
    },

    {
      id: 2, 
      path: "/welcome-page",
      element:
        <WelcomeUserPage
          handleAccountPopupOpen={handleAccountPopupOpen}
          currentUser={currentUser}
          authorized={isAuthorized}
          name={currentUser.username}
        />
    ,
    },

    {
      id: 3,
      path: "/profile",
      element: 
        <AccountMainPage
          authorized={isAuthorized}
          name={currentUser.username}
          accountPopupOpened={accountPopupOpened}
          handleAccountPopupClose={handleAccountPopupClose}
          handleAccountPopupOpen={handleAccountPopupOpen}
          handleSignOut={onSignOut}
          currentUser={currentUser}
          onUpdateUser={onUpdateUser}
        />
      ,
    },

    {
      id: 4,
      path: "/profile/tests",
      element: <AccountTestsPage 
      authorized={isAuthorized}
			name={currentUser.username}
			currentUser={currentUser}
			accountPopupOpened={accountPopupOpened}
			handleAccountPopupClose={
				handleAccountPopupClose
			}
			handleAccountPopupOpen={
				handleAccountPopupOpen
			}
			handleSignOut={onSignOut}
			setCurrentLocation={setCurrentLocation}
      
      />,
    },

    {
      id: 5,
      path: "/profile/courses",
      element: <AccountCoursesPage 
      authorized={isAuthorized}
			name={currentUser.username}
			currentUser={currentUser}
			accountPopupOpened={accountPopupOpened}
			handleAccountPopupClose={
				handleAccountPopupClose
			}
			handleAccountPopupOpen={
				handleAccountPopupOpen
			}
			handleSignOut={onSignOut}
      />,
    },

    {
      id: 6,
      path: "/",
      element: <Main 
      authorized={isAuthorized}
			name={currentUser.username}
      />,
    },

    {
      id: 7,
      path: "/welcome-tests/:numberTest",
      element: <StartTest 
        authorized={isAuthorized}
			  name={currentUser.username}
      />,
    },

    {
      id: 8,
      path: "/tests/:numberTest",
      element: <Tests 
        authorized={isAuthorized}
        name={currentUser.username}
      />,
    },

    {
      id: 9,
      path: "/test-results/:numberTest",
      element: <TestResults 
        authorized={isAuthorized}
				currentLocation={currentLocation}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
      />,
    },

    {
      id: 10,
      path: "/ege",
      element: <EgePage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 11,
      path: "/sign-in",
      element: <Login handleLogin={handleLogin}/>,
    },

    {
      id: 12,
      path: "/sign-up",
      element: <Registration 
        handleRegistration={handleRegistration}
      />,
    },

    {
      id: 13,
      path: "/about",
      element: <About />,
    },

    {
      id: 14,
      path: "/teachers",
      element: <Teachers />,
    },

    {
      id: 15,
      path: "/practicum/:numberTest",
      element: <BuyPracticumPopup />,
    },

    {
      id: 16,
      path: "/first-game/:gameNumber",
      element: <FirstGame />,
    },

    {
      id: 17,
      path: "/second-game/:gameNumber",
      element: <SecondGame />,
    },
    {
      id: 30,
      path: "/third-game/:gameNumber",
      element: <ThirdGame />,
    },
    {
      id: 18,
      path: "/reviews",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 18,
      path: "/all-curses",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 19,
      path: "/1-4-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 20,
      path: "/1-4-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
        name={currentUser.username}
      />,
    },

    {
      id: 21,
      path: "/5-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 22,
      path: "/6-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 23,
      path: "/7-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 24,
      path: "/8-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 25 ,
      path: "/9-class",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 26 ,
      path: "/oge",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 27,
      path: "/composition",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 28,
      path: "/final-essay",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 29,
      path: "/restore-password",
      element: <InDevelopPage 
        authorized={isAuthorized}
				name={currentUser.username}
      />,
    },

    {
      id: 31,
      path: "/fourth-game/:gameID",
      element: <FourthGame />,
    },
  ];

  return (
    <Routes>
      {paths.map((path) => (
        <Route key={path.id} path={path.path} element={path.element} />
      ))}
    </Routes>
  );
}
