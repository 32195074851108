import React from 'react';
import './ResultMultipleCheckbox.scss';

const ResultMultipleCheckbox = ({answerOption}) => {

    if(answerOption.answered && !answerOption.is_correct) {
        return (
            <div className="multiple-answer__result-square multiple-answer__result-square_wrong">
                <div className="multiple-answer__result-square-inner multiple-answer__result-square-inner_wrong"></div>
            </div>
        )
    } else if (answerOption.answered && answerOption.is_correct) {
        return (
            <div className="multiple-answer__result-square multiple-answer__result-square_correct">
                <div className="multiple-answer__result-square-inner multiple-answer__result-square-inner_correct"></div>
            </div>
        )
    } else if (!answerOption.answered && answerOption.is_correct) {
        return (
            <div className="multiple-answer__result-square multiple-answer__result-square_not-answered">
                <div className="multiple-answer__result-square-inner multiple-answer__result-square-inner_not-answered"></div>
            </div>
        )
    } else if (!answerOption.answered && !answerOption.is_correct) {
        return (
            <div className="multiple-answer__result-square multiple-answer__result-square">
                <div className="multiple-answer__result-square-inner"></div>
            </div>
        )
    }
};

export default ResultMultipleCheckbox;