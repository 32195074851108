import { combineReducers, configureStore } from "@reduxjs/toolkit";
import pageWidthReducer from "./redusers/pageWidthReducer";
import gameSlice from "../pages/games/store/gameSlice";

const rootReducer = combineReducers({
	game: gameSlice,
	pageWidth: pageWidthReducer,
});

export const store = configureStore({
	reducer: rootReducer,
});
