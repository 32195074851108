import React from 'react';
import {useSelector} from "react-redux";
import './Step.scss';

export const Step = ({width, index, isWrong, game}) => {
    const count = useSelector((state) => state.game.countSteps);

    let colored;
    let coloredWrong;
    let progressBarStep = 'progress-bar__step';

    switch (game) {
        case "one":
            colored = 'colored-one';
            coloredWrong = 'colored-one-wrong';
            break;
        case "two":
            colored = 'colored-two';
            coloredWrong = 'colored-two-wrong';
            progressBarStep += ' progress-bar__step-two';
            break;
        case "three":
            colored = 'colored-three';
            coloredWrong = 'colored-three-wrong';
            progressBarStep += ' progress-bar__step-three';
            break;
         case "four":
            colored = 'colored-four';
            coloredWrong = 'colored-four-wrong';
            progressBarStep += ' progress-bar__step-four';
            break;
        default:
            colored = 'colored-one';
            coloredWrong = 'colored-one-wrong';
    }

    return (
        <li
            key={index}
            className={`${progressBarStep} ${isWrong ? coloredWrong : index < count ? colored : ''}`}
            style={{width: width}}
        />
    );
};