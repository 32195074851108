import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import './InsertInput.scss';

export const InsertInput = ({ question, handleInsertInput, input, checkedAnswers, answer }) => {
    const {pathname} = useLocation();
    const {numberTest} = useParams();

    if(pathname === `/tests/${numberTest}`) {
        return (
            <div className="insert-input__bottom-content">
                {question.is_word === false ?
                    <p className="insert-input__help-text">Пишите цифры по порядку и без пробелов.</p>
                    :
                    <p className="insert-input__help-text">Пишите слова слитно, без пробелов между ними.</p>
                }
                <input onChange={(e) => handleInsertInput(e)} className="insert-input__input" value={input} type="text" maxLength="40" placeholder="Ответ:"></input>
            </div>
        );
    } else if (pathname === `/test-results/${numberTest}`) {
        return (
            <div className="insert-input__results-area">
                { checkedAnswers.right_question && checkedAnswers.right_question[question.id] !== 1 &&
                    <div className="insert-input__results-wrong">
                        <p className="insert-input__results-title-wrong">Твой ответ:</p>
                        <p className="insert-input__results-answer">{answer[1].join()}</p>
                    </div>
                }
                <div className="insert-input__results-correct">
                    <p className="insert-input__results-title-correct">Верный ответ:</p>
                    <p className="insert-input__results-answer">{answer[0][0].correct_answer}</p>
                </div>
            </div>
        )
    }
};
