import React from 'react';
import './AccountPopup.scss';
import EmailIcon from '../images/email-icon.svg';
import PhotoIcon from '../images/change-pgoto-icon.svg';
import PasswordIcon from '../images/change-password-icon.svg';
import FriendIcon from '../images/invite-friend-icon.svg';
import {ClosePopupButton} from '../../../ui/ClosePopupButton';

export const AccountPopup = ({
  currentUser,
  accountPopupOpened,
  handleAccountPopupClose,
  handleSignOut,
}) => {
  const [password, setPassword] = React.useState('');

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className={accountPopupOpened ? 'account-popup account-popup_opened' : 'account-popup'}>
      <ClosePopupButton
        handleClosePopup={handleAccountPopupClose}
        classes="close-popup__button_absolute"
      />
      <div className="account-popup__container">
        <p className="account-popup__name-text">Привет, {currentUser.username}!</p>
        <ul className="account-popup__menu">
          <li className="account-popup__menu-item">
            <img className="account-popup__menu-icon" src={EmailIcon} alt="Иконка e-mail" />
            <p className="account-popup__menu-text">{currentUser.email}</p>
          </li>
          <li className="account-popup__menu-item account-popup__button hidden">
            <img className="account-popup__menu-icon" src={PasswordIcon} alt="Иконка пароль" />
            <button className="account-popup__menu-button">Сменить пароль</button>
          </li>
          <li className="hidden account-popup__menu-item">
            <img className="account-popup__menu-icon" src={PhotoIcon} alt="Иконка фото" />
            <p className="account-popup__menu-text">Сменить фото</p>
          </li>
          <li className="hidden account-popup__menu-item account-popup__button">
            <img className="account-popup__menu-icon" src={FriendIcon} alt="Иконка друзей" />
            <button className="account-popup__menu-button">Пригласить друга</button>
          </li>
        </ul>
        <form className="hidden">
          <input type="text" value={password || ''} onChange={(e) => passwordHandler(e)}></input>
          <button type="submit">Сохранить</button>
        </form>

        <button onClick={() => handleSignOut()} className="account-popup__exit-button">
          Выход
        </button>
      </div>
    </div>
  );
};
